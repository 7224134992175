import React from "react";
import specialitysymbollist from "./specialitysymbols";
import { Carousel } from "antd";

function SpecialCard() {
  return (
    <section className="bg-[#342c49] font-['poppins']">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 font-sans text-3xl text-center font-bold leading-none tracking-tight text-black sm:text-6xl md:mx-auto">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 48 20"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="18302e52-9e2a-4c8e-9550-0cbb21b38e55"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#18302e52-9e2a-4c8e-9550-0cbb21b38e55)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative text-[#fbf8ff]">Best</span>
            </span>{" "}
            <span className="text-[#fbf8ff]">Dentistry</span>{" "}
            <span className="relative text-[#fbf8ff]">Consultation In </span>
            <span className="text-[#fbf8ff]">Specialized</span>{" "}
            <span className="relative text-[#fbf8ff]">Fields</span>
          </h2>
        </div>
        <div className="grid gap-4 text-[#fbf8ff] row-gap-10 grid-cols-3 sm:grid-cols-3 lg:grid-cols-3 sm:grid-cols-3 md:grid-cols-3">
          <div className="max-w-md sm:mx-auto sm:text-center">
            <div className="flex mx-auto items-center justify-center w-16 h-16 mb-4 sm:mx-auto sm:w-24 sm:h-24">
              <img
                class="rounded-t-lg"
                src={specialitysymbollist[0].src}
                alt="bridge"
              />
            </div>
            <h6 className="mb-3 text-center justify-center text-xl font-bold leading-5">
              Bridge
            </h6>
          </div>
          <div className="max-w-md sm:mx-auto sm:text-center">
            <div className="flex mx-auto items-center justify-center w-16 h-16 mb-4 sm:mx-auto sm:w-24 sm:h-24">
              <img
                class="rounded-t-lg"
                src={specialitysymbollist[1].src}
                alt="crown"
              />
            </div>
            <h6 className="mb-3 text-center justify-center text-xl font-bold leading-5">
              Crown
            </h6>
          </div>
          <div className="max-w-md sm:mx-auto sm:text-center">
            <div className="flex mx-auto items-center justify-center w-16 h-16 mb-4 sm:mx-auto sm:w-24 sm:h-24">
              <img
                class="rounded-t-lg"
                src={specialitysymbollist[4].src}
                alt="implant"
              />
            </div>
            <h6 className="mb-3 text-center justify-center text-xl font-bold leading-5">
              Implant
            </h6>
          </div>
          <div className="max-w-md sm:mx-auto sm:text-center">
            <div className="flex mx-auto items-center justify-center w-16 h-16 mb-4 sm:mx-auto sm:w-24 sm:h-24">
              <img
                class="rounded-t-lg"
                src={specialitysymbollist[2].src}
                alt="denture"
              />
            </div>
            <h6 className="mb-3 text-center justify-center text-xl font-bold leading-5">
              Denture
            </h6>
          </div>
          <div className="max-w-md sm:mx-auto sm:text-center">
            <div className="flex mx-auto items-center justify-center w-16 h-16 mb-4 sm:mx-auto sm:w-24 sm:h-24">
              <img
                class="rounded-t-lg"
                src={specialitysymbollist[3].src}
                alt="isd"
              />
            </div>
            <h6 className="mb-3 mx-auto text-center justify-center text-xl font-bold leading-5">
              Implant Supported Denture
            </h6>
          </div>
          <div className="max-w-md sm:mx-auto sm:text-center">
            <div className="flex mx-auto items-center justify-center w-16 h-16 mb-4 sm:mx-auto sm:w-24 sm:h-24">
              <img
                class="rounded-t-lg"
                src={specialitysymbollist[5].src}
                alt="laminates"
              />
            </div>
            <h6 className="mb-3 text-center justify-center text-xl font-bold leading-5">
              Laminates
            </h6>
          </div>
          <div className="max-w-md sm:mx-auto sm:text-center">
            <div className="flex mx-auto items-center justify-center w-16 h-16 mb-4 sm:mx-auto sm:w-24 sm:h-24">
              <img
                class="rounded-t-lg"
                src={specialitysymbollist[6].src}
                alt="rpd"
              />
            </div>
            <h6 className="mb-3 text-center justify-center text-xl font-bold leading-5">
              Removal Partial Denture
            </h6>
          </div>
          <div className="max-w-md sm:mx-auto sm:text-center">
            <div className="flex mx-auto items-center justify-center w-16 h-16 mb-4 sm:mx-auto sm:w-24 sm:h-24">
              <img
                class="rounded-t-lg"
                src={specialitysymbollist[7].src}
                alt="splint"
              />
            </div>
            <h6 className="mb-3 text-center justify-center text-xl font-bold leading-5">
              Splint
            </h6>
          </div>
          <div className="max-w-md sm:mx-auto sm:text-center">
            <div className="flex mx-auto items-center justify-center w-16 h-16 mb-4 sm:mx-auto sm:w-24 sm:h-24">
              <img
                class="rounded-t-lg"
                src={specialitysymbollist[8].src}
                alt="smile"
              />
            </div>
            <h6 className="mb-3 text-center justify-center text-xl font-bold leading-5">
              Smile Designing
            </h6>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SpecialCard;
