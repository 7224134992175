import React from "react";
import Footer from "../Footer";
import NavBar from "../NavBar";
import AboutMe from "./HomePages/AboutMe";
import Booking from "./HomePages/Booking";
import Center from "./HomePages/Center";
import SpecialCard from "./HomePages/Speciality";

const Home = () => {
  return (
    <section>
      <NavBar />
      <Center />
      <SpecialCard />
      <Booking />
      <AboutMe />
      <Footer />
    </section>
  );
};

export default Home;
