import { Input, Table } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../../redux/alertsSlice";
import Layout from "../../Layout/Layout";
import { Dialog } from "@headlessui/react";

const UserList = () => {
  const { user } = useSelector((state) => state.user);
  const [searchedText, setSearchedText] = useState("");
  const [users, setUsers] = useState([]);
  const [recordedId, setRecordedId] = useState("");
  let [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const getUserData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/admin/get-all-users", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setUsers(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getUserData();
  }, [setUsers]);

  const blockHandler = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.post(
        "/api/admin/block-user",
        { id: recordedId },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        setIsOpen(false);
        window.location.reload();
        toast.success(response.data.message);
      }
    } catch (error) {
      dispatch(hideLoading());
      toast.error("Error Deleting User");
    }
  };

  console.log(recordedId);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (value, record) => {
        return moment(record.createdAt).format("DD-MM-YYYY");
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, record) => (
        <div>
          <a
            className="actionbtn"
            onClick={() => {
              setRecordedId(record._id);
              setIsOpen(true);
            }}
          >
            Delete
          </a>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <section className="text-[#342c49] font-['poppins']">
        <h1 className="text-4xl mb-3">User List</h1>
        <hr></hr>
        <Input.Search
          className="block w-1/2 h-16 mt-4 px-5 py-3 text-base md:h-16 lg:h-16"
          placeholder="Search here with name..."
          onSearch={(value) => {
            setSearchedText(value);
          }}
        />
        <section className="h-screen">
          <Table
            className="mt-5 overflow-x-auto overflow-y-auto"
            columns={columns}
            pagination={{
              pageSize: 100000,
            }}
            dataSource={users}
          />
        </section>
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
          <div
            class="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div class="fixed inset-0 z-10 overflow-y-auto">
              <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div class="sm:flex sm:items-start">
                      <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <svg
                          class="h-6 w-6 text-red-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                          />
                        </svg>
                      </div>
                      <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <h3
                          class="text-base font-semibold leading-6 text-gray-900"
                          id="modal-title"
                        >
                          Delete User
                        </h3>
                        <div class="mt-2">
                          <p class="text-sm text-gray-500">
                            Are you sure you want to delete this user? All their
                            data will be permanently removed. This action cannot
                            be undone.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      onClick={blockHandler}
                      type="button"
                      class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    >
                      Delete
                    </button>
                    <button
                      onClick={() => setIsOpen(false)}
                      type="button"
                      class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </section>
    </Layout>
  );
};

export default UserList;
