import { DatePicker, Form, Input, Select, TimePicker } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout/Layout";
import { hideLoading, showLoading } from "../../redux/alertsSlice";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const Consultation = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [isAvailable, setIsAvailable] = useState(false);
  const [date, setDate] = useState();
  const [selectedTimings, setSelectedTimings] = useState();
  const navigate = useNavigate();
  const finishHandler = async (values) => {
    setIsAvailable(false);
    console.log(values);
    try {
      dispatch(showLoading());
      const response = await axios.post(
        "/api/user/consultations",
        {
          ...values,
          userId: user._id,
          date: date,
          timings: selectedTimings,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      dispatch(hideLoading());
      if (response.data.success) {
        toast.success(response.data.message);
        navigate("/dashboard");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      dispatch(hideLoading());
      toast.error("Something Went Wrong");
    }
  };

  const checkAvailability = async () => {
    try {
      dispatch(showLoading());

      const resAvail = await axios.post(
        "/api/user/check-availability",
        {
          date: date,
          timings: selectedTimings,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(hideLoading());
      if (resAvail.data.success) {
        setIsAvailable(true);
        toast.success(resAvail.data.message);
      } else {
        toast.error(resAvail.data.message);
      }
    } catch (error) {
      dispatch(hideLoading());
      toast.error("Something Went Wrong");
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };
  return (
    <Layout>
      <section className="text-[#342c49] font-['poppins'] h-fit">
        <h1 className="text-4xl mb-3">Consultation</h1>
        <hr></hr>
        <h5 className="mt-3 mb-3 !text-red-600 text-sm">
          *First Consultation is Free of Charge! Pricing for further
          consultations will be tailored based on the case*
        </h5>
        <Form onFinish={finishHandler}>
          <div className="!mb-0 !mt-0 sm:w-full">
            <Form.Item name="name">
              <Input
                className="block w-full h-16 mt-4 px-5 py-3 text-base text-[#fbf8ff] placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-[#342c49] focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300 md:h-16 lg:h-16 sm:w-1/2 md:w-1/2 lg:w-1/2"
                placeholder="Name"
              />
            </Form.Item>
          </div>
          <div className="grid grid-cols-1 !mb-0 !mt-0 gap-5 md:grid-cols-2 lg:grid-cols-2 rounded-2xl">
            <div className="!mb-0 !mt-0">
              <Form.Item className="!w-full !mb-0 !mt-0" name="title">
                <Select
                  className="profilepicture !w-full !mb-0 !mt-0 border border-[#342c49] text-[#988ab5] rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Title"
                >
                  <Select.Option value="-" />
                  <Select.Option value="Student" />
                  <Select.Option value="Patient" />
                  <Select.Option value="Doctor" />
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item className="w-full !mb-0 !mt-0" name="type">
                <Select
                  className="profilepicture !w-full !mb-2 !mt-0 border border-[#342c49] text-[#988ab5] rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Consultation Category"
                >
                  <Select.Option className="!w-1/2" value="-" />
                  <Select.Option
                    className="!w-1/2"
                    value="Patient Consultation"
                  />
                  <Select.Option
                    className="!w-1/2"
                    value="Research Paper Review"
                  />
                  <Select.Option
                    className="!w-1/2"
                    value="Theory Topic Consultation"
                  />
                  <Select.Option className="!w-1/2" value="Lab Consultation" />
                  <Select.Option className="!w-1/2" value="Exam Consultation" />
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="sm:!mb-0 sm:!mt-0 md:!mb-0 md:!mt-0 sm:max-full">
            <Form.Item
              name="description"
              rules={[
                {
                  max: 50,
                  message: "Description should be less than 50 character",
                },
              ]}
              validateTrigger="onBlur"
            >
              <Input
                className="block w-full !h-16 !mb-0 !mt-0 px-5 py-3 text-base text-[#fbf8ff] placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-[#342c49] focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300 md:h-[10rem] lg:h-[10rem]"
                placeholder="Enter Brief Description"
              />
            </Form.Item>
          </div>

          <div className="sm:!mb-0 sm:!mt-0 md:!mb-0 md:!mt-0 sm:max-full">
            <Form.Item name="detaileddescription">
              <Input
                className="block w-full h-[6rem] !mb-0 !mt-0 px-5 py-3 text-base text-[#fbf8ff] placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-[#342c49] focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300 md:h-[10rem] lg:h-[10rem]"
                placeholder="Enter Detailed Description"
              />
            </Form.Item>
          </div>
          <div className="grid grid-cols-2 gap-2 lg:grid-cols-6 md:grid-cols-6 xl:grid-cols-6">
            <div className="mb-0 w-[15rem] md:w-[18rem]">
              <DatePicker
                name="date"
                format="DD-MM-YYYY"
                onChange={(value) => {
                  setIsAvailable(false);
                  setDate(dayjs(value).format("DD-MM-YYYY"));
                }}
                disabledDate={disabledDate}
                className="block w-[8rem] h-[4rem] !text-[#fbf8ff] rounded-lg bg-[#342c49] focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
              />
            </div>
            <div className="w-[15rem] md:w-[18rem]">
              <TimePicker
                name="timings"
                format="HH:mm"
                disabledHours={() => [
                  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 18, 19, 20, 21, 22, 23,
                ]}
                hideDisabledOptions={true}
                onChange={(values) => {
                  setIsAvailable(false);
                  setSelectedTimings(dayjs(values).format("HH:mm"));
                }}
                className="block w-[8rem] h-[4rem] !text-[#fbf8ff] rounded-lg bg-[#342c49] focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
              />
            </div>
          </div>

          <div>
            {isAvailable && (
              <button
                className="inline items-center h-10 w-[8rem] mt-2 justify-center p-0.5 mb-0 mr-2 overflow-hidden text-[1rem] font-medium text-gray-900 rounded-lg group bg-[#988ab5] text-[#fbf8ff] hover:bg-gradient-to-r from-[#342c49] to-[#342c49]"
                type="submit"
              >
                Book Now
              </button>
            )}
          </div>
        </Form>

        <button
          className="inline items-center h-10 w-[12rem] justify-center p-0.5 mb-0 mt-2 mr-2 overflow-hidden text-l font-medium text-gray-900 rounded-lg group bg-[#988ab5] text-[#fbf8ff] hover:bg-gradient-to-r from-[#342c49] to-[#342c49]"
          type="submit"
          onClick={checkAvailability}
        >
          Check Availability
        </button>
      </section>
    </Layout>
  );
};

export default Consultation;
