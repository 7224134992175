import React, { useEffect, useRef, useState } from "react";
import UserPayment from "./UserPayment";
import { hideLoading, showLoading } from "../../redux/alertsSlice";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@headlessui/react";

const UserPaymentSuccess = () => {
  let [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  return (
    <section className="text-[#342c49] font-['poppins']">
      <UserPayment />
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          navigate("/userpayments");
        }}
      >
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start justify-center">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 text-green-500 dark:text-green-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div className="text-center sm:ml-4 sm:text-center">
                    <div className="mt-2">
                      <p className="text-m text-black-500">
                        Payment Successful
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 justify-center sm:flex sm:px-6">
                  <button
                    onClick={() => {
                      setIsOpen(false);
                      navigate("/userpayments");
                    }}
                    type="button"
                    className="mt-2 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>{" "}
    </section>
  );
};

export default UserPaymentSuccess;
