import { Input, Select, Table } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../../redux/alertsSlice";
import Layout from "../../Layout/Layout";
import { Option } from "antd/lib/mentions";
import Conversation from "./conversation";
import Message from "./message";
import { io } from "socket.io-client";
import { IoMdSend } from "react-icons/io";
import { AiOutlinePaperClip } from "react-icons/ai";
import noAvatar from "../../../Images/default-avatar.png";
import { Dialog } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

const Messenger = () => {
  const { user } = useSelector((state) => state.user);
  const [users, setUsers] = useState([]);
  const [name, setName] = useState("");
  const [conversations, setConversations] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [userInfos, setUserInfos] = useState([]);
  const [fileData, setFileData] = useState(null);
  let [isOpen, setIsOpen] = useState(false);
  const socket = useRef();
  const scrollRef = useRef();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    socket.current = io("https://nodeserver-i2g5.onrender.com/", {
      transports: ["websocket"],
    });
    socket.current.on("getMessage", (data) => {
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
    });
  }, []);

  useEffect(() => {
    arrivalMessage &&
      currentChat?.members.includes(arrivalMessage.sender) &&
      setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);

  useEffect(() => {
    socket.current.emit("addUser", user?._id);
  }, [user]);

  const getUserData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/admin/get-all-users", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setUsers(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    getUserData();
  }, []);

  const handleNameChange = (value) => {
    const user = users.find((u) => u.name === value);
    setName(user._id);
  };

  const handleStartChat = async (e) => {
    try {
      const { _id: senderId } = user;
      const response = await axios.post(
        "/api/admin/conversationstart",
        {
          members: { senderId, receiverId: name },
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success) {
        toast.success(response.data.message);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      toast.error("Conversation Already Exists");
    }
  };
  const getAllConversations = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/admin/get-all-conversations", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setConversations(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    getAllConversations();
  }, []);

  useEffect(() => {
    const getMessages = async () => {
      try {
        const res = await axios.get("/api/admin/messages/" + currentChat?._id);
        setMessages(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getMessages();
  }, [currentChat]);

  const handleChatSubmit = async (e) => {
    e.preventDefault();
    const message = {
      sender: user._id,
      text: newMessage,
      conversationId: currentChat._id,
    };

    const receiverId = currentChat.members.find(
      (member) => member !== user._id
    );

    socket.current.emit("sendMessage", {
      senderId: user._id,
      receiverId,
      text: newMessage,
    });

    try {
      const res = await axios.post("/api/admin/messages", message);
      setMessages([...messages, res.data]);
      setNewMessage("");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    async function fetchUserInfos() {
      try {
        const receiver = currentChat.members.find(
          (member) => member !== user._id
        );
        console.log(receiver);

        const response = await axios.get(
          `/api/admin/get-user-info-conversation/${receiver}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (response.data.success) {
          setUserInfos(response.data.data);
          console.log(userInfos);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchUserInfos();
  }, [currentChat?.members]);

  const handleDeleteChat = async () => {
    try {
      const response = await axios.post(
        `/api/admin/conversation/delete-chat/${currentChat._id}`,
        null,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success) {
        toast.success(response.data.message);
        setIsOpen(false);
        setCurrentChat([]);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fileChangeHandler = (e) => {
    setFileData(e.target.files[0]);
  };

  const imageHandler = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("pic", fileData);
      data.append("sender", user._id); // Append sender to the FormData
      data.append("conversationId", currentChat._id);

      const message = {
        sender: user._id,
        image: data,
        conversationId: currentChat._id,
      };

      const receiverId = currentChat.members.find(
        (member) => member !== user._id
      );

      socket.current.emit("sendMessage", {
        senderId: user._id,
        receiverId,
        image: fileData,
      });

      const res = await axios.post("/api/admin/image-message", data, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the Content-Type header to send FormData
        },
      });
      setMessages([...messages, res.data]);
      setNewMessage("");
      setFileData(null);
      inputRef.current.form.reset();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Layout>
      <section className="text-[#342c49] font-['poppins'] h-screen overflow-auto">
        <h1 className="text-4xl mb-3">Message Center</h1>
        <hr></hr>
        {user?.role === "admin" && (
          <div>
            <Select
              className="ml-1 sm:ml-[3rem] md:ml-[3rem] lg:ml-[3rem] mt-4 sm:mb-10 md:mb-10 lg:mb-10 xl:mb-10 w-[13.2rem] sm:w-[20rem] md:w-[20rem] lg:w-[20rem] xl:w-[20rem]"
              placeholder="Name"
              onChange={handleNameChange}
            >
              {users &&
                users.map((username) => (
                  <Option key={username._id} value={username.name}></Option>
                ))}
            </Select>
            <button
              onClick={handleStartChat}
              type="submit"
              class="w-full mt-6 ml-1 sm:ml-[3rem] md:ml-[3rem] sm:mt-0 md:mt-0 lg:mt-0 l xl:mt-0 h-16 sm:w-auto inline-flex justify-center items-center gap-x-3 text-center bg-[#988ab5] text-[#fbf8ff] hover:bg-gradient-to-r from-[#342c49] to-[#342c49] border border-transparent font-medium rounded-md transition py-3 px-4"
            >
              New User Chat
            </button>
          </div>
        )}
        <div className="lg:grid xl:grid lg:grid-cols-4 xl:grid-cols-4">
          <div>
            <h1 className="mt-[1rem] ml-1 sm:ml-[3rem] md:ml-[3rem] lg:ml-[3rem] text-[#342c49] font-bold text-xl underline">
              Open Conversations
            </h1>
            <div className="ml-1 sm:ml-[3rem] md:ml-[3rem] lg:ml-[3rem] mt-[2rem]">
              {conversations.map((c) => (
                <div
                  className="cursor-pointer"
                  onClick={() => setCurrentChat(c)}
                >
                  <Conversation conversation={c} currentUser={user} />{" "}
                </div>
              ))}
            </div>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
              <div
                class="relative z-10"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
              >
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                <div class="fixed inset-0 z-10 overflow-y-auto">
                  <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div class="sm:flex sm:items-start">
                          <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <svg
                              class="h-6 w-6 text-red-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                              />
                            </svg>
                          </div>
                          <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <h3
                              class="text-base font-semibold leading-6 text-gray-900"
                              id="modal-title"
                            >
                              Delete Chat
                            </h3>
                            <div class="mt-2">
                              <p class="text-sm text-gray-500">
                                Are you sure you want to delete this chat? All
                                of your data will be permanently removed. This
                                action cannot be undone.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          onClick={handleDeleteChat}
                          type="button"
                          class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                        >
                          Delete
                        </button>
                        <button
                          onClick={() => setIsOpen(false)}
                          type="button"
                          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
          <div className="col-span-3">
            {currentChat ? (
              <>
                <div className="ml-1 sm:ml-[3rem] md:ml-[3rem]">
                  <h1 className="lg:hidden xl:hidden mt-[1rem] text-[#342c49] font-bold text-xl underline">
                    Current Chat
                  </h1>
                  <div className="grid grid-cols-5">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      className="mt-[1rem] mb-2 col-span-4"
                    >
                      {userInfos?.profilePicture?.[0]?.image && (
                        <img
                          src={userInfos.profilePicture[0].image}
                          className="w-12 h-12"
                          alt="Profile"
                          onLoad={() => console.log("Image loaded")}
                        />
                      )}
                      {userInfos?.userName?.[0]?.name && (
                        <h1 className="ml-3 text-center">
                          {userInfos.userName[0].name}
                        </h1>
                      )}
                    </div>
                    <p
                      onClick={() => {
                        setIsOpen(true);
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      className="underline font-bold mt-2"
                    >
                      Delete Chat
                    </p>
                  </div>
                  <hr className="text-[#342c49]"></hr>
                  <div className="h-96 overflow-y-scroll">
                    {messages.map((m) => (
                      <div ref={scrollRef}>
                        <Message message={m} own={m.sender === user._id} />
                      </div>
                    ))}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <textarea
                      className="block w-full !h-[6rem] !mb-0 mt-10 px-5 py-3 text-[#342c49] placeholder-[#988ab5] transition duration-500 ease-in-out transform border border-[#342c49] rounded-lg bg-[#fbf8ff] focus:outline-none focus:border-[#342c49] focus:ring-1 focus:ring-[#342c49] focus:ring-offset-2 focus:ring-offset-[#988ab5] lg:h-[10rem]"
                      placeholder="write something..."
                      onChange={(e) => setNewMessage(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          handleChatSubmit();
                        }
                      }}
                      value={newMessage}
                    ></textarea>

                    <button
                      className="mt-[2rem] ml-10"
                      onClick={handleChatSubmit}
                    >
                      <IoMdSend className="w-8 h-8" />
                    </button>
                  </div>
                  <div className="grid grid-cols-8 gap-3">
                    <div className="w-full col-span-6">
                      <h1 className="mt-3">Want to send us your X-Ray?</h1>
                    </div>
                    <form onSubmit={imageHandler}>
                      <div className="grid grid-cols-2 gap-9 items-end">
                        <label
                          className="mt-3 cursor-pointer"
                          htmlFor="fileInput"
                        >
                          <AiOutlinePaperClip className="w-8 h-8 mr-2" />
                        </label>
                        <input
                          id="fileInput"
                          ref={inputRef}
                          type="file"
                          className="hidden"
                          onChange={fileChangeHandler}
                          multiple={true}
                        />
                        <button className="mt-2" type="submit">
                          <IoMdSend className="w-8 h-8" />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </>
            ) : (
              <div className="flex justify-center items-center h-full">
                <span className="mt-5 text-[#342c49] text-xl font-bold">
                  Select a user to start conversation.
                </span>
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Messenger;
