import { Table } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout/Layout";
import { hideLoading, showLoading } from "../../redux/alertsSlice";
import moment from "moment";
import { setUser } from "../../redux/userSlice";
import { get } from "lodash";

const Appointments = () => {
  const { user } = useSelector((state) => state.user);
  const [appointments, setAppointments] = useState([]);
  const dispatch = useDispatch();
  const getAppointmentsData = useCallback(async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/user/get-all-appointments", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setAppointments(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  }, []);
  useEffect(() => {
    const data = window.localStorage.getItem("appointmentlist");
    const saveddata = JSON.parse(data);
    setAppointments(saveddata);
    getAppointmentsData();
  }, []);
  useEffect(() => {
    const appointmentData = window.localStorage.setItem(
      "appointmentlist",
      JSON.stringify(appointments)
    );
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },

    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (value, record) => {
        return moment(record.date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Time",
      dataIndex: "timings",
      render: (value, record) => {
        return moment(record.timings).format("HH:mm");
      },
    },
  ];

  return (
    <Layout>
      <section className="text-[#342c49] font-['poppins'] h-fit">
        <h1 className="text-4xl mb-3">Appointments List</h1>
        <hr></hr>
        <Table
          className="mt-10 overflow-x-auto overflow-y-auto"
          columns={columns}
          pagination={{
            pageSize: 100000,
          }}
          dataSource={appointments}
        />
      </section>
    </Layout>
  );
};

export default Appointments;
