import { Table } from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout/Layout";
import { hideLoading, showLoading } from "../../redux/alertsSlice";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Tabs } from "flowbite-react";
import PayButton from "./Payment/PayButton";

const UserPayment = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [receivedPaymentData, setreceivePaymentData] = useState("");
  const [paidPaymentData, setPaidPaymentData] = useState("");

  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);
  const navigate = useNavigate();

  const getReceivedPaymentData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get(
        "/api/user/get-all-received-payment-request",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        setreceivePaymentData(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getReceivedPaymentData();
  }, []);

  const getPaidPaymentData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/user/get-all-paid-payment", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setPaidPaymentData(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    getPaidPaymentData();
  }, []);

  const columns = [
    {
      title: "Brief Description",
      dataIndex: "description",
    },
    {
      title: "Detailed Description",
      dataIndex: "description",
    },
    {
      title: "Cost",
      dataIndex: "cost",
    },
    {
      title: "Currency",
      dataIndex: "currency",
    },
  ];

  const paidPaymentColumn = [
    {
      title: "Email",
      dataIndex: ["products", "0", "email"],
    },
    {
      title: "Customer Id",
      dataIndex: "customerId",
    },
    {
      title: "Payment Id",
      dataIndex: "paymentIntentId",
    },
    {
      title: "Description",
      dataIndex: "products",
      render: (products) =>
        products.map((products) => products.description).join(),
    },
    {
      title: "Cost",
      dataIndex: "total",
      render: (total) => <h1>{total / 100}</h1>,
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
    },
  ];

  return (
    <Layout>
      <section className="text-[#342c49] font-['poppins']">
        <h1 className="text-4xl mb-3">Payment</h1>
        <hr></hr>
        <Tabs.Group
          style="default"
          ref={tabsRef}
          onActiveTabChange={(tab) => setActiveTab(tab)}
        >
          <Tabs.Item active title="Pending">
            <section className="h-screen overflow-auto">
              <Table
                className="mt-0 overflow-x-auto overflow-y-auto"
                columns={columns}
                pagination={{
                  pageSize: 100000,
                }}
                dataSource={receivedPaymentData}
              />
              {receivedPaymentData.length > 0 ? (
                <PayButton receivedPaymentData={receivedPaymentData} />
              ) : (
                <h1 className="text-xl font-bold mt-3">
                  You have No Pending Payments
                </h1>
              )}
            </section>
          </Tabs.Item>
          <Tabs.Item active title="Paid">
            <section className="h-screen overflow-auto">
              <Table
                className="mt-0 overflow-x-auto overflow-y-auto"
                columns={paidPaymentColumn}
                pagination={{
                  pageSize: 100000,
                }}
                dataSource={paidPaymentData}
              />
            </section>
          </Tabs.Item>
        </Tabs.Group>
      </section>
    </Layout>
  );
};

export default UserPayment;
