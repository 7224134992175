// import { Tabs } from "antd";
import { Button, Tabs } from "flowbite-react";
import axios from "axios";
import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import { hideLoading, showLoading } from "../../redux/alertsSlice";
import { setUser } from "../../redux/userSlice";

const Notifications = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);
  const markAllAsSeen = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.post(
        "/api/user/mark-all-notifications-as-seen",
        {
          userId: user._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        toast.success(response.data.message);
        dispatch(setUser(response.data.data));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      dispatch(hideLoading());
      toast.error("Something Went Wrong");
    }
  };

  const deleteAllSeen = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.post(
        "/api/user/delete-all-seen-notifications",
        {
          userId: user._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        toast.success(response.data.message);
        dispatch(setUser(response.data.data));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      dispatch(hideLoading());
      toast.error("Something Went Wrong");
    }
  };

  return (
    <Layout>
      <section className="text-[#342c49] font-['poppins']">
        <h1 className="text-4xl mb-3">Notifications</h1>
        <hr></hr>
        <Tabs.Group
          style="default"
          ref={tabsRef}
          onActiveTabChange={(tab) => setActiveTab(tab)}
        >
          <Tabs.Item active title="Unread">
            <div className="flex justify-end">
              <a
                className="text-[#342c49] text-semibold text-lg cursor-pointer underline"
                onClick={markAllAsSeen}
              >
                Mark All As Seen
              </a>
            </div>
            <section className="h-screen overflow-auto">
              <div>
                {user?.unseenNotifications.map((notification) => (
                  <div>
                    <div className="text-xl mt-5 mb-2">
                      {notification.message}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </Tabs.Item>
          <Tabs.Item active title="Read">
            <div className="flex justify-end">
              <a
                className="text-[#342c49] text-semibold text-lg cursor-pointer underline"
                onClick={deleteAllSeen}
              >
                Delete All
              </a>
            </div>
            <section className="h-screen overflow-auto">
              <div>
                {user?.seenNotifications.map((notification) => (
                  <div>
                    <div className="text-xl mt-5 mb-2">
                      {notification.message}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </Tabs.Item>
        </Tabs.Group>
      </section>
    </Layout>
  );
};

export default Notifications;
