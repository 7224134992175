import { Card } from "flowbite-react";
import Layout from "../Layout/Layout";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../redux/alertsSlice";
import axios from "axios";
import moment from "moment";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [latestAppointment, setLatestAppointments] = useState("");
  const getAppointmentsData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/user/get-latest-appointment", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setLatestAppointments(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getAppointmentsData();
  }, [setLatestAppointments]);

  return (
    <Layout>
      <section className="text-[#342c49] font-['poppins'] h-screen">
        <h1 className="text-4xl mb-3">Dashboard</h1>
        <hr></hr>
        <div className="mt-10 max-w-sm">
          <Card>
            <div className="mb-4 flex items-center justify-between">
              <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                Upcoming Appointments
              </h5>

              <a
                href="#"
                className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
              >
                View all
              </a>
            </div>
            <div className="flow-root">
              <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                <li className="py-3 sm:py-4">
                  {latestAppointment &&
                    latestAppointment.map((appointment, index) => (
                      <div>
                        <h5>Your Title: {appointment.type}</h5>
                        <h5>Description: {appointment.description}</h5>
                        <h5>
                          On: {new Date(appointment.date).toLocaleDateString()}
                        </h5>
                        <h5>
                          At: {moment(appointment.timings).format("HH:mm")}
                        </h5>
                      </div>
                    ))}
                </li>
              </ul>
            </div>
          </Card>
        </div>
      </section>
    </Layout>
  );
};

export default Dashboard;
