import { Button, Col, Form, Input, Row, Table } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { hideLoading, showLoading } from "../../../redux/alertsSlice";
import { Tabs } from "flowbite-react";

const PostBlogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);
  const [fileData, setFileData] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [blogs, setBlogs] = useState([]);

  const finishHandler = async () => {
    try {
      dispatch(showLoading());
      const data = new FormData();
      data.append("pic", fileData);
      data.append("title", title);
      data.append("description", description);
      const response = await axios.post("/api/admin/add-blogs", data);
      dispatch(hideLoading());
      if (response.data.success) {
        toast.success(response.data.message);
        navigate("/dashboard");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      dispatch(hideLoading());
      toast.error("Something Went Wrong");
    }
  };

  const titleChangeHandler = (e) => {
    setTitle(e.target.value);
  };

  const descriptionChangeHandler = (e) => {
    setDescription(e.target.value);
  };

  const fileChangeHandler = (e) => {
    setFileData(e.target.files[0]);
  };

  const getBlogs = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/admin/get-all-blogs", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setBlogs(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);

  const makeHighlightBlog = async (record, highlight) => {
    try {
      dispatch(showLoading());
      const response = await axios.post(
        "/api/admin/make-highlight-blog",
        { blogId: record._id, highlight: highlight },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        toast.success(response.data.message);
        getBlogs();
      }
    } catch (error) {
      toast.error("Error Changing Status");

      dispatch(hideLoading());
    }
  };

  const deleteBlog = async (record) => {
    try {
      dispatch(showLoading());
      const response = await axios.post(
        "/api/admin/delete-blog",
        { blogId: record._id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        toast.success(response.data.message);
        getBlogs();
      }
    } catch (error) {
      toast.error("Error Changing Status");

      dispatch(hideLoading());
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Highlight Blog",
      dataIndex: "highlight",
    },
    {
      title: "Make Highlight Blog",
      dataIndex: "actions",
      render: (text, record) => (
        <div>
          <a onClick={() => makeHighlightBlog(record, "true")}>Change </a>
        </div>
      ),
    },
    {
      title: "Delete Blog",
      dataIndex: "actions",
      render: (text, record) => (
        <div>
          <a onClick={() => deleteBlog(record)}>Delete </a>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <section className="text-[#342c49] font-['poppins'] h-fit">
        <h1 className="text-4xl mb-3">Blogs</h1>
        <hr></hr>
        <Tabs.Group
          style="default"
          ref={tabsRef}
          onActiveTabChange={(tab) => setActiveTab(tab)}
        >
          <Tabs.Item active title="Post">
            <Form className="mt-10" onFinish={finishHandler}>
              <div className="grid grid-cols-2">
                <div className="mt-5 mr-2">
                  <Form.Item name="title">
                    <Input
                      className="block w-full px-5 py-3 text-base text-[#342c49] placeholder-[#988ab5] transition duration-500 ease-in-out transform border border-[#342c49] rounded-lg bg-[#fbf8ff] focus:outline-none focus:border-[#342c49] focus:ring-1 focus:ring-[#342c49] focus:ring-offset-2 focus:ring-offset-[#988ab5]"
                      placeholder="Enter the Blog Title"
                      value={title}
                      onChange={titleChangeHandler}
                    />
                  </Form.Item>
                  <Form.Item name="description">
                    <Input
                      className="block w-full h-[20rem] px-5 py-3 text-base text-[#342c49] placeholder-[#988ab5] transition duration-500 ease-in-out transform border border-[#342c49] rounded-lg bg-[#fbf8ff] focus:outline-none focus:border-[#342c49] focus:ring-1 focus:ring-[#342c49] focus:ring-offset-2 focus:ring-offset-[#988ab5]"
                      placeholder="Enter the Blog Description"
                      value={description}
                      onChange={descriptionChangeHandler}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Input
                      className="block w-full px-5 py-3 text-base text-[#342c49] placeholder-[#988ab5] transition duration-500 ease-in-out transform border border-[#342c49] rounded-lg bg-[#fbf8ff] focus:outline-none focus:border-[#342c49] focus:ring-1 focus:ring-[#342c49] focus:ring-offset-2 focus:ring-offset-[#988ab5]"
                      placeholder="Select the Image"
                      type="file"
                      onChange={fileChangeHandler}
                      multiple="true"
                    />
                  </Form.Item>
                </div>
                <div>
                  <button className="h-full" type="submit">
                    <img
                      className="uploadimage"
                      alt="upload"
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/image-upload-28244.appspot.com/o/uploadicon%2F7869470_download_man_upload_transfer_document_icon.png?alt=media&token=53678d11-f137-4e1d-ac3d-5483d6550fd0"
                      }
                    />
                    <img
                      className="uploadiconmobile"
                      alt="uploadmobile"
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/image-upload-28244.appspot.com/o/uploadicon%2Fuploadicon.png?alt=media&token=0c429d73-eabc-4e56-b4f2-c2480cdce90b"
                      }
                      style={{ display: "none" }}
                    />
                  </button>
                </div>
                <span className="text-[#342c49]">
                  *Click on the image to upload blog*
                </span>
              </div>
            </Form>
          </Tabs.Item>
          <Tabs.Item active title="Update">
            <section className="h-screen overflow-auto">
              <Table
                className="mt-5 overflow-x-auto overflow-y-auto"
                columns={columns}
                pagination={{
                  pageSize: 10,
                }}
                dataSource={blogs}
              />
            </section>
          </Tabs.Item>
        </Tabs.Group>
      </section>
    </Layout>
  );
};

export default PostBlogs;
