import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../../redux/alertsSlice";
import { HiLockClosed } from "react-icons/hi";

const PayButton = (receivedPaymentData) => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handleCheckout = () => {
    dispatch(showLoading());

    axios
      .post(
        "/api/user/create-checkout-session",
        {
          receivedPaymentData,
          userId: user._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )

      .then((res) => {
        dispatch(hideLoading());

        if (res.data.url) {
          dispatch(showLoading());

          window.location.href = res.data.url;
        }
        dispatch(hideLoading());
      })
      .catch((err) => console.log(err.message));
  };
  return (
    <button
      onClick={handleCheckout}
      className="bg-[#988ab5] text-[#fbf8ff] text-[1rem] hover:bg-gradient-to-r from-[#342c49] to-[#342c49] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-m px-5 py-2.5 text-center mr-2 mb-2"
    >
      CheckOut
    </button>
  );
};

export default PayButton;
