import React, { useEffect, useState } from "react";
import { Card, Table } from "flowbite-react";
import Layout from "../../Layout/Layout";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../../redux/alertsSlice";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLegend,
  VictoryTheme,
} from "victory";
import couple from "../../../Images/couple.png";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const [latestAppointment, setLatestAppointments] = useState("");
  const [totalUsers, setTotalUsers] = useState("");
  const [consultations, setConsultations] = useState([]);
  const [patients, setPatients] = useState([]);
  const [students, setStudents] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [inr, setInr] = useState("");
  const [eur, setEur] = useState("");
  const [usd, setUsd] = useState("");

  const getUpcomingAppointment = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/admin/upcoming-appointment", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setLatestAppointments(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getUpcomingAppointment();
  }, [setLatestAppointments]);

  const getTotalUsers = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/admin/total-users", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setTotalUsers(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getTotalUsers();
  }, [setTotalUsers]);

  const getConsultationData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get(
        "/api/admin/get-all-consultations-by-month",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        setConsultations(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getConsultationData();
  }, []);

  const getPatientsData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/admin/get-all-patients-in-month", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setPatients(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getPatientsData();
  }, []);

  const getStudentsData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/admin/get-all-students-in-month", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setStudents(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getStudentsData();
  }, []);

  const getDoctorsData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/admin/get-all-doctors-in-month", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setDoctors(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    getDoctorsData();
  }, []);

  const getCollectedInr = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/admin/get-all-collected-inr", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setInr(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    getCollectedInr();
  }, [setInr]);

  const getCollectedEur = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/admin/get-all-collected-eur", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setEur(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    getCollectedEur();
  }, [setEur]);

  const getCollectedUsd = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/admin/get-all-collected-usd", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setUsd(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    getCollectedUsd();
  }, [setUsd]);

  const data = [
    {
      x: "Jan",
      y: consultations[0],
    },
    {
      x: "Feb",
      y: consultations[1],
    },
    {
      x: "Mar",
      y: consultations[2],
    },
    {
      x: "Apr",
      y: consultations[3],
    },
    {
      x: "May",
      y: consultations[4],
    },
    {
      x: "Jun",
      y: consultations[5],
    },
    {
      x: "Jul",
      y: consultations[6],
    },
    {
      x: "Aug",
      y: consultations[7],
    },
    {
      x: "Sep",
      y: consultations[8],
    },
    {
      x: "Oct",
      y: consultations[9],
    },

    {
      x: "Nov",
      y: consultations[10],
    },
    {
      x: "Dec",
      y: consultations[11],
    },
  ];

  const patient = [
    {
      x: "Jan",
      y: patients[0],
    },
    {
      x: "Feb",
      y: patients[1],
    },
    {
      x: "Mar",
      y: patients[2],
    },
    {
      x: "Apr",
      y: patients[3],
    },
    {
      x: "May",
      y: patients[4],
    },
    {
      x: "Jun",
      y: patients[5],
    },
    {
      x: "Jul",
      y: patients[6],
    },
    {
      x: "Aug",
      y: patients[7],
    },
    {
      x: "Sep",
      y: patients[8],
    },
    {
      x: "Oct",
      y: patients[9],
    },

    {
      x: "Nov",
      y: patients[10],
    },
    {
      x: "Dec",
      y: patients[11],
    },
  ];

  const student = [
    {
      x: "Jan",
      y: students[0],
    },
    {
      x: "Feb",
      y: students[1],
    },
    {
      x: "Mar",
      y: students[2],
    },
    {
      x: "Apr",
      y: students[3],
    },
    {
      x: "May",
      y: students[4],
    },
    {
      x: "Jun",
      y: students[5],
    },
    {
      x: "Jul",
      y: students[6],
    },
    {
      x: "Aug",
      y: students[7],
    },
    {
      x: "Sep",
      y: students[8],
    },
    {
      x: "Oct",
      y: students[9],
    },

    {
      x: "Nov",
      y: students[10],
    },
    {
      x: "Dec",
      y: students[11],
    },
  ];

  const doctor = [
    {
      x: "Jan",
      y: doctors[0],
    },
    {
      x: "Feb",
      y: doctors[1],
    },
    {
      x: "Mar",
      y: doctors[2],
    },
    {
      x: "Apr",
      y: doctors[3],
    },
    {
      x: "May",
      y: doctors[4],
    },
    {
      x: "Jun",
      y: doctors[5],
    },
    {
      x: "Jul",
      y: doctors[6],
    },
    {
      x: "Aug",
      y: doctors[7],
    },
    {
      x: "Sep",
      y: doctors[8],
    },
    {
      x: "Oct",
      y: doctors[9],
    },

    {
      x: "Nov",
      y: doctors[10],
    },
    {
      x: "Dec",
      y: doctors[11],
    },
  ];

  const inrTotal = Array.isArray(inr)
    ? inr.reduce((acc, cur) => acc + cur.total / 100, 0)
    : 0;
  const eurTotal = Array.isArray(eur)
    ? eur.reduce((acc, cur) => acc + cur.total / 100, 0)
    : 0;
  const usdTotal = Array.isArray(usd)
    ? usd.reduce((acc, cur) => acc + cur.total / 100, 0)
    : 0;
  const inreurTotal = Array.isArray(inr)
    ? inr.reduce((acc, cur) => acc + (cur.total * 0.011) / 100, 0)
    : 0;
  const usdeurTotal = Array.isArray(usd)
    ? usd.reduce((acc, cur) => acc + (cur.total * 0.93) / 100, 0)
    : 0;

  const total = inreurTotal + eurTotal + usdeurTotal;

  return (
    <Layout>
      <section className="text-[#342c49] font-['poppins']">
        <h1 className="text-4xl mb-3">Dashboard</h1>
        <hr></hr>
        <div className="mt-10">
          <div className="gap-2 md:grid grid-cols-3">
            <Card>
              <div className="mb-4 flex items-center justify-between">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                  Upcoming Appointments
                </h5>
                <a className="text-sm font-medium text-[#988ab5] hover:underline">
                  <Link to="/admin/consultationlist">View all</Link>
                </a>
              </div>
              <div className="flex items-center space-x-4">
                {latestAppointment &&
                  latestAppointment.map((appointment, index) => (
                    <div>
                      <h3 className="text-xl !font-semibold">
                        Your Title:{" "}
                        <span className="!font-normal">{appointment.type}</span>
                      </h3>
                      <h3 className="text-xl !font-semibold">
                        Description:{" "}
                        <span className="!font-normal">
                          {appointment.description}
                        </span>
                      </h3>
                      <h3 className="text-xl !font-semibold">
                        On:{" "}
                        <span className="!font-normal">
                          {new Date(appointment.date).toLocaleDateString()}
                        </span>
                      </h3>
                      <h3 className="text-xl !font-semibold">
                        At:{" "}
                        <span className="!font-normal">
                          {moment(appointment.timings).format("HH:mm")}
                        </span>
                      </h3>
                    </div>
                  ))}
              </div>
            </Card>
            <Card>
              <div className="mb-4 flex items-center justify-between">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                  Total Money
                </h5>
              </div>
              <Table className="text-lg">
                <Table.Head>
                  <Table.HeadCell className="bg-[#fbf8ff] text-[1rem]">
                    Currency
                  </Table.HeadCell>
                  <Table.HeadCell className="bg-[#fbf8ff] text-[1rem]">
                    Amount
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  <Table.Row className="bg-white">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      INR
                    </Table.Cell>
                    <Table.Cell className="text-[#342c49]">
                      {inrTotal}₹
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      EUR
                    </Table.Cell>
                    <Table.Cell className="text-[#342c49]">
                      {eurTotal}€
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      USD
                    </Table.Cell>
                    <Table.Cell className="text-[#342c49]">
                      {usdTotal}$
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-bold text-gray-900 dark:text-white">
                      Total
                    </Table.Cell>
                    <Table.Cell className="text-[#342c49] font-bold">
                      {total}€
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Card>

            <Card>
              <div className="mb-[4.5rem] flex items-center justify-between">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                  Total Users
                </h5>
                <a className="text-sm font-medium text-[#988ab5] hover:underline">
                  <Link to="/admin/userlist">View all</Link>
                </a>
              </div>
              <div className="flex items-center justify-between space-x-4">
                <img alt="couple" src={couple} className="w-20 h-20" />
                <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
                  {totalUsers}
                </h1>
              </div>
            </Card>
          </div>
          <div className="gap-2 md:grid grid-cols-2">
            <Card>
              <VictoryChart
                height={500}
                width={600}
                theme={VictoryTheme.material}
                domainPadding={{ x: 20, y: [0, 10] }}
              >
                <VictoryLegend
                  x={300}
                  y={70}
                  centerTitle
                  orientation="horizontal"
                  gutter={20}
                  style={{
                    border: { stroke: "black" },
                    title: { fontSize: 20 },
                  }}
                  data={[{ name: "Total Appointments" }]}
                  colorScale={["#988ab5"]}
                />

                <VictoryBar style={{ data: { fill: "#988ab5" } }} data={data} />
              </VictoryChart>
            </Card>
            <Card>
              <VictoryChart
                theme={VictoryTheme.material}
                height={500}
                width={600}
              >
                <VictoryLegend
                  x={300}
                  y={60}
                  centerTitle
                  orientation="horizontal"
                  gutter={20}
                  style={{
                    border: { stroke: "black" },
                    title: { fontSize: 20 },
                  }}
                  data={[
                    { name: "Patient" },
                    { name: "Student" },
                    { name: "Doctor" },
                  ]}
                  colorScale={["#988ab5", "#342c49", "#4E3EE5"]}
                />
                <VictoryGroup
                  vertical
                  offset={30}
                  style={{ data: { width: 4 } }}
                  colorScale={["#988ab5", "#342c49", "#4E3EE5"]}
                >
                  <VictoryBar data={patient} />
                  <VictoryBar data={student} />
                  <VictoryBar data={doctor} />
                </VictoryGroup>
              </VictoryChart>
            </Card>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AdminDashboard;
