import { Carousel } from "antd";
import specialitylist from "./specialitylistimages";

const Booking = () => {
  return (
    <section className="bg-[#fbf8ff] font-['poppins']">
      <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div class="md:grid md:grid-cols-2 mt-12 mb-12 md:items-center md:gap-12 xl:gap-32">
          <div class="mt-5 sm:mt-8 lg:mt-0">
            <div class="space-y-6 sm:space-y-8">
              <div class="space-y-1 md:space-y-4">
                <h2 class="text-3xl lg:text-4xl tracking-snug leading-normal font-bold text-[#342c49]">
                  Got <span className="text-[#342c49]">Dental </span> Problems?{" "}
                </h2>{" "}
                <h2 class="text-3xl !mt-2 lg:text-4xl tracking-snug leading-normal font-bold text-[#342c49]">
                  Need <span className="text-[#342c49]">Consultation </span> for
                  your patients?
                </h2>
              </div>
              <button className="w-full !mt-5 sm:w-auto inline-flex justify-center items-center gap-x-3 text-center text-xl font-medium text-gray-900 rounded-lg group bg-[#988ab5] text-[#fbf8ff] hover:bg-gradient-to-r from-[#342c49] to-[#342c49]">
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-[#988ab5] text-[#fbf8ff] hover:bg-gradient-to-r from-[#342c49] to-[#342c49] rounded-md group-hover:bg-opacity-0">
                  Contact Now{" "}
                </span>
              </button>
            </div>
          </div>

          <div className="mt-4">
            <Carousel className="carousel" autoplay>
              <img
                className="object-cover w-full h-96 rounded shadow-lg"
                src={specialitylist[0].src}
                alt="sp1"
              />
              <img
                className="object-cover w-full h-96 rounded shadow-lg"
                src={specialitylist[1].src}
                alt="sp2"
              />
              <img
                className="object-cover w-full h-96 rounded shadow-lg"
                src={specialitylist[2].src}
                alt="sp3"
              />
              <img
                className="object-cover w-full h-96 rounded shadow-lg"
                src={specialitylist[3].src}
                alt="sp4"
              />
              <img
                className="object-cover w-full h-96 rounded shadow-lg"
                src={specialitylist[4].src}
                alt="sp5"
              />
              <img
                className="object-cover w-full h-96 rounded shadow-lg"
                src={specialitylist[5].src}
                alt="sp6"
              />
              <img
                className="object-cover w-full h-96 rounded shadow-lg"
                src={specialitylist[6].src}
                alt="sp7"
              />
              <img
                className="object-cover w-full h-96 rounded shadow-lg"
                src={specialitylist[7].src}
                alt="sp8"
              />
              <img
                className="object-cover w-full h-96 rounded shadow-lg"
                src={specialitylist[8].src}
                alt="sp9"
              />
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Booking;
