import React, { useEffect, useState } from "react";
import NavBar from "../NavBar";
import Footer from "../Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { HiArrowLeft, HiArrowRight, HiOutlineBookmark } from "react-icons/hi";
import { Avatar, Card } from "antd";
import { hideLoading, showLoading } from "../redux/alertsSlice";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
const { Meta } = Card;

function BlogsDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState();
  const [allBlogs, setAllBlogs] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);

  const { id } = params;
  const dispatch = useDispatch();
  const getBlogById = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get(`/api/user/blogs/${id}`);
      dispatch(hideLoading());
      if (response.data.success) {
        setBlogs(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getBlogById();
  }, [id]);

  const getAllBlogs = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/user/get-all-blogs");
      dispatch(hideLoading());
      if (response.data.success) {
        setAllBlogs(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getBlogById();
    getAllBlogs();
  }, []);

  const handlePrev = () => {
    const currentIndex = allBlogs.findIndex((b) => b._id === id);
    const previousIndex = Math.max(0, currentIndex - 1);
    navigate(`/blogs/${allBlogs[previousIndex]._id}`);
    if (currentIndex === previousIndex) {
      toast("You Reached End");
    }
  };

  const handleNext = () => {
    const currentIndex = allBlogs.findIndex((b) => b._id === id);
    const nextIndex = Math.min(allBlogs.length - 1, currentIndex + 1);
    navigate(`/blogs/${allBlogs[nextIndex]._id}`);
    if (currentIndex === nextIndex) {
      toast("You Reached End");
    }
  };
  return (
    <section>
      <div className="navbar z-[10000]">
        <NavBar />
      </div>
      <section className="font-['poppins']">
        <section className="bg-[#fbf8ff] text-[#342c49] font-['poppins']">
          <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl xl:max-w-screen-xl md:px-24 lg:px-8 lg:py-14">
            <div className="mb-4 md:mx-auto text-center sm:text-center lg:max-w-2xl md:mb-12">
              <h2 className="mb-6 text-7xl mx:auto font-bold leading-none tracking-tight text-gray-900 sm:text-8xl md:mx-auto">
                <span className="relative inline-block">
                  <Link to="/blogs">
                    <span className="relative text-[#342c49]">Blog Spot</span>
                  </Link>
                </span>{" "}
              </h2>
            </div>
          </div>
          {blogs &&
            blogs.map((blog, index) => (
              <div class="m-auto flex flex-col px-5 py-8 sm:px-10 md:px-10 lg:px-20 xl:px-20 font-['poppins'] lg:max-w-screen-xl xl:max-w-screen-xl">
                <div class="flex flex-col mb-8 prose">
                  <div class="w-full mx-auto">
                    <h1 className="text-left text-4xl font-semibold leading-none tracking-tight md:text-5xl lg:text-5xl xl:text-5xl">
                      {blog.title}
                    </h1>
                    <span className="text-sm">Published On: {blog.date}</span>
                    <div>
                      <img
                        className="m-auto mb-5 mt-5 sm:mt-5 md:mt-5 lg:mt-10 xl:mt-10 w-1/2 h-1/2"
                        alt={blog.title}
                        src={blog.image}
                      />
                    </div>

                    <p className="text-justify">{blog.description} </p>
                  </div>
                </div>
              </div>
            ))}
          <div class="flex flex-row px-5 py-8 sm:px-10 md:px-10 lg:px-20 xl:px-20">
            {allBlogs && (
              <>
                {currentIndex < allBlogs.length - 1 && (
                  <button className="mx-auto underline" onClick={handlePrev}>
                    <HiArrowLeft className="mx-auto" /> Previous
                  </button>
                )}
                <Link className="mx-auto underline" to="/blogs">
                  <HiOutlineBookmark className="mx-auto" />
                  Show All
                </Link>
                {currentIndex < allBlogs.length - 1 && (
                  <button className="mx-auto underline" onClick={handleNext}>
                    <HiArrowRight className="mx-auto" /> Next
                  </button>
                )}
              </>
            )}
          </div>
        </section>
        <Footer />
      </section>
    </section>
  );
}
export default BlogsDetail;
