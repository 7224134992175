const specialitysymbollist = [
  {
    src: "https://firebasestorage.googleapis.com/v0/b/image-upload-28244.appspot.com/o/specialitysymbols%2F20220625-Bridge.png?alt=media&token=77fab301-7c8f-4aea-8745-9d01ee5267ab",
    label: "S1",
    id: 1,
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/image-upload-28244.appspot.com/o/specialitysymbols%2F20220625-Crown.png?alt=media&token=1e120092-e239-4373-82d9-e87d719d53a8",
    label: "S2",
    id: 2,
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/image-upload-28244.appspot.com/o/specialitysymbols%2F20220625-Denture.png?alt=media&token=0b74f44d-9b8b-4afb-a240-c0c9ee4e3f77",
    label: "S3",
    id: 3,
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/image-upload-28244.appspot.com/o/specialitysymbols%2F20220625-ISD.png?alt=media&token=3bec6833-3155-44c9-924b-3d7eaf923eac",
    label: "S4",
    id: 4,
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/image-upload-28244.appspot.com/o/specialitysymbols%2F20220625-Implant.png?alt=media&token=e9a2ec78-4dfa-47fa-bfc1-3cee4cceec35",
    label: "S5",
    id: 5,
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/image-upload-28244.appspot.com/o/specialitysymbols%2F20220625-Laminates.png?alt=media&token=0bddfdd6-7e39-41de-af0d-54f9f0a2de3e",
    label: "S6",
    id: 6,
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/image-upload-28244.appspot.com/o/specialitysymbols%2F20220625-RPD.png?alt=media&token=9c696803-2ec4-49c3-a403-859083b1d43a",
    label: "S7",
    id: 7,
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/image-upload-28244.appspot.com/o/specialitysymbols%2F20220625-Splint.png?alt=media&token=67b403ba-ea22-427e-92a3-09e486622f61",
    label: "S8",
    id: 8,
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/image-upload-28244.appspot.com/o/specialitysymbols%2Fsmile1.png?alt=media&token=c80bf727-b6ed-492a-8ce3-80e233732060",
    label: "S9",
    id: 9,
  },
];

export default specialitysymbollist;
