import "./message.css";
export default function Message({ message, own }) {
  return (
    <div className={own ? "message own" : "message"}>
      <div className="messageTop">
        <p className="messageText">{message.text}</p>
      </div>
      {message.image && (
        <img className="w-60 h-60" src={message.image} alt="sentImage" />
      )}
    </div>
  );
}
