import React from "react";
import NavBar from "../NavBar";
import Footer from "../Footer";

const Contact = () => {
  return (
    <section>
      <div className="navbar z-[10000]">
        <NavBar />
      </div>
      <section className="font-['poppins']">
        <section className="bg-[#fbf8ff] text-[#342c49] font-['poppins']">
          <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-14">
            <div className="mb-4 md:mx-auto text-center sm:text-center lg:max-w-2xl md:mb-12">
              <h2 className="mb-6 text-7xl mx:auto font-bold leading-none tracking-tight text-gray-900 sm:text-8xl md:mx-auto">
                <span className="relative text-[#342c49]">Contact</span>
              </h2>
            </div>
          </div>
          <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-14">
            <div class="w-full">
              <h1 className="text-2xl text-left font-normal mb-4 leading-normal tracking-tight md:mb-16 lg:mb-16 xl:mb-16">
                Currently you can reach out to Dr. Manali Somani only through
                the consultation page! <br></br>
                <span>
                  Register yourself and send out your first consultation request
                  to have a one-on-one dental consultation!
                </span>
              </h1>
            </div>
          </div>
        </section>
        <Footer />
      </section>
    </section>
  );
};

export default Contact;
