import React, { useEffect, useRef, useState } from "react";
import { Input, Table } from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../../redux/alertsSlice";
import Layout from "../../Layout/Layout";
import toast from "react-hot-toast";
import moment from "moment";
import { Tabs } from "flowbite-react";

const ConsultationList = () => {
  const { user } = useSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);
  const [searchedText, setSearchedText] = useState("");
  const [pendingConsultations, setpendingConsultations] = useState([]);
  const [approvedConsultations, setapprovedConsultations] = useState([]);
  const [declinedConsultations, setdeclinedConsultations] = useState([]);
  const dispatch = useDispatch();
  const getConsultationData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/admin/get-all-consultations", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setpendingConsultations(response.data.data.pendingconsultation);
        setapprovedConsultations(response.data.data.approvedconsultation);
        setdeclinedConsultations(response.data.data.declinedconsultation);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  const changeStatus = async (record, status) => {
    try {
      dispatch(showLoading());
      const response = await axios.post(
        "/api/admin/change-consultation-status",
        { consultationId: record._id, userId: record.userId, status: status },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        toast.success(response.data.message);
        getConsultationData();
      }
    } catch (error) {
      toast.error("Error Changing Status");

      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getConsultationData();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Type",
      dataIndex: "title",
    },
    {
      title: "Description",
      dataIndex: "detaileddescription",
      width: "50%",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (value, record) => {
        return moment(record.date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Time",
      dataIndex: "timings",
      render: (value, record) => {
        return moment(record.timings).format("HH:mm");
      },
    },

    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, record) => (
        <div>
          {/* {record.status === "Pending" && ( */}
          <a
            className="actionbtn"
            onClick={() => changeStatus(record, "Approved")}
          >
            Accept{" "}
          </a>
          {/* )} */}
          {/* {record.status === "Approved" && ( */}
          <span> /</span>
          <a
            className="actionbtn"
            onClick={() => changeStatus(record, "Declined")}
          >
            Decline
          </a>
          {/* )} */}
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <section className="text-[#342c49] font-['poppins']">
        <h1 className="text-4xl mb-3">Consultation List</h1>
        <hr></hr>
        <Tabs.Group
          style="default"
          ref={tabsRef}
          onActiveTabChange={(tab) => setActiveTab(tab)}
        >
          <Tabs.Item active title="Pending">
            <Input.Search
              className="block w-1/2 h-16 mt-4 px-5 py-3 text-base md:h-16 lg:h-16"
              placeholder="Search here with name..."
              onSearch={(value) => {
                setSearchedText(value);
              }}
            />
            <section className="h-screen overflow-auto">
              <Table
                className="mt-5 overflow-x-auto overflow-y-auto"
                columns={columns}
                pagination={{
                  pageSize: 10,
                }}
                dataSource={pendingConsultations}
              />
            </section>
          </Tabs.Item>
          <Tabs.Item active title="Approved">
            <Input.Search
              className="block w-1/2 h-16 mt-4 px-5 py-3 text-base md:h-16 lg:h-16"
              placeholder="Search here with name..."
              onSearch={(value) => {
                setSearchedText(value);
              }}
            />
            <section className="h-screen overflow-auto">
              <Table
                className="mt-5 overflow-x-auto overflow-y-auto"
                columns={columns}
                pagination={{
                  pageSize: 10,
                }}
                dataSource={approvedConsultations}
              />
            </section>
          </Tabs.Item>
          <Tabs.Item active title="Declined">
            <Input.Search
              className="block w-1/2 h-16 mt-4 px-5 py-3 text-base md:h-16 lg:h-16"
              placeholder="Search here with name..."
              onSearch={(value) => {
                setSearchedText(value);
              }}
            />
            <section className="h-screen overflow-auto">
              <Table
                className="mt-5 overflow-x-auto overflow-y-auto"
                columns={columns}
                pagination={{
                  pageSize: 10,
                }}
                dataSource={declinedConsultations}
              />
            </section>
          </Tabs.Item>
        </Tabs.Group>
      </section>
    </Layout>
  );
};

export default ConsultationList;
