//jshint esversion:6
import { Form, Input } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import NavBar from "../NavBar";
import { hideLoading, showLoading } from "../redux/alertsSlice";
import axios from "axios";
import toast from "react-hot-toast";
import registerloginnobg from "../Images/register-login-nobg.png";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  const [showPassword, setShowPassword] = useState(false);
  const onFinish = async (values) => {
    try {
      dispatch(showLoading());
      const response = await axios.post("/api/user/login", values);
      dispatch(hideLoading());
      if (response.data.success) {
        toast.success(response.data.message);
        localStorage.setItem("token", response.data.data);
        navigate("/dashboard");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      dispatch(hideLoading());

      toast.error("Something Went Wrong");
    }
  };

  const showPasswordHandler = () => {
    setShowPassword((prevState) => !prevState);
  };
  return (
    <section>
      <div className="navbar z-[10000]">
        <NavBar />
      </div>
      <section className="bg-[#fbf8ff] font-['poppins']">
        <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 md:px-12 lg:px-24 lg:py-24">
          <div className="justify-center mx-auto text-left align-bottom transition-all transform bg-[#fbf8ff] rounded-lg sm:align-middle sm:max-w-2xl sm:w-full">
            <div className="grid flex-wrap items-center justify-center grid-cols-1 mx-auto shadow-2xl shadow-[#342c49] lg:grid-cols-2 rounded-2xl">
              <div className="w-full px-6 py-3 text-[#342c49]">
                <div>
                  <div className="mt-3 text-left sm:mt-5">
                    <div className="inline-flex items-center w-full">
                      <h3 className="text-lg font-bold leading-6 lg:text-5xl">
                        Login
                      </h3>
                    </div>
                    <div className="mt-4 text-base">
                      <p>Login and Book Your Appointment!</p>
                    </div>
                  </div>
                </div>

                <div className="mt-6 space-y-2">
                  <Form onFinish={onFinish}>
                    <Form.Item name="email">
                      <Input
                        type="email"
                        className="block w-full px-5 py-3 text-base text-[#342c49] placeholder-[#988ab5] transition duration-500 ease-in-out transform border border-[#342c49] rounded-lg bg-[#fbf8ff] focus:outline-none focus:border-[#342c49] focus:ring-1 focus:ring-[#342c49] focus:ring-offset-2 focus:ring-offset-[#988ab5]"
                        placeholder="Enter Your EMail Address"
                      />
                    </Form.Item>
                    <Form.Item name="password">
                      <Input
                        type={showPassword ? "text" : "password"}
                        className="block w-full px-5 py-3 text-base text-[#342c49] placeholder-[#988ab5] transition duration-500 ease-in-out transform border border-[#342c49] rounded-lg bg-[#fbf8ff] focus:outline-none focus:border-[#342c49] focus:ring-1 focus:ring-[#342c49] focus:ring-offset-2 focus:ring-offset-[#988ab5]"
                        placeholder="Enter Your Password"
                      />
                    </Form.Item>
                    <button
                      type="button"
                      className="text-[#342c49] font-medium justify-items-end text-sm px-1 py-0 mb-0"
                      onClick={showPasswordHandler}
                    >
                      Show Password
                    </button>

                    <div className="flex flex-col mt-4 lg:space-y-2">
                      <Link
                        to="/register"
                        className="text-[#342c49] font-medium text-sm px-1 py-0 mr-0 mb-0 "
                      >
                        Not Registered? Click Here!
                      </Link>
                      <button
                        type="submit"
                        className="bg-[#988ab5] text-[#fbf8ff] text-[1rem] hover:bg-gradient-to-r from-[#342c49] to-[#342c49] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-m px-5 py-2.5 text-center mr-2 mb-2"
                      >
                        Sign In
                      </button>{" "}
                    </div>
                  </Form>
                </div>
              </div>
              <div className="order-first hidden w-full lg:block">
                <img
                  className="object-cover h-full bg-cover rounded-l-lg"
                  src={registerloginnobg}
                  alt="registerlogin"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </section>
  );
}

export default Login;
