import React, { useEffect, useState } from "react";
import NavBar from "../NavBar";
import Footer from "../Footer";
import { hideLoading, showLoading } from "../redux/alertsSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Carousel, Card, Avatar } from "antd";
import Meta from "antd/lib/card/Meta";

const Blogs = () => {
  const [blogs, setBlogs] = useState();
  const [highlights, setHighlight] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { id } = params;

  const getAllBlogs = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/user/get-all-blogs");
      dispatch(hideLoading());
      if (response.data.success) {
        setBlogs(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  const getHighlightBlog = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/user/get-highlight-blog");
      dispatch(hideLoading());
      if (response.data.success) {
        setHighlight(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, []);
  useEffect(() => {
    getHighlightBlog();
  }, []);

  const addEllipsis = (str, limit) => {
    return str.length > limit
      ? str.substring(0, limit) + "...  Read More"
      : str;
  };

  return (
    <section>
      <div className="navbar z-[10000]">
        <NavBar />
      </div>
      <section className="bg-[#fbf8ff] text-[#342c49] font-['poppins']">
        <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-14">
          <div className="max-w-xl mb-4 text-center md:mx-auto sm:text-center lg:max-w-2xl md:m-4">
            <h2 className="mb-6 text-7xl mx:auto font-bold leading-none tracking-tight text-gray-900 sm:text-8xl md:mx-auto">
              <span className="relative inline-block">
                <span className="relative text-[#342c49]">Blog Spot</span>
              </span>{" "}
            </h2>
          </div>
        </div>
        <div className="px-4 py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
          <div className="grid gap-4 row-gap-8 lg:grid-cols-6">
            <div className="lg:col-span-2">
              <div className="mb-3">
                {highlights &&
                  highlights.map((highlight, index) => (
                    <Link to={`/blogs/${highlight._id}`}>
                      <div>
                        <p className="text-sm text-[#988ab5]">
                          {highlight.date}
                        </p>
                        <h3 className="text-4xl font-extrabold leading-none tracking-tight lg:text-6xl xl:text-7xl sm:max-w-full md:max-w-full lg:max-w-screen-xl">
                          {highlight.title}
                        </h3>
                        <h5 className="text-[#988ab5] mb-4 mt-4 text-base text-gray-700 md:text-lg">
                          {addEllipsis(highlight.description, 250)}
                        </h5>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
            <div class="hidden md:hidden sm:hidden lg:block xl:block ml-[6rem]"></div>
            <div className="flex flex-col h-[25rem] overflow-y-auto space-y-8 lg:col-span-3">
              <span className="text-end">Scroll For More</span>

              <div className="ml-6 mt-6 mb-3">
                {blogs &&
                  blogs.map((blog, index) => (
                    <Link to={`/blogs/${blog._id}`}>
                      <div>
                        <p className="text-sm text-[#988ab5]">{blog.date}</p>
                        <h3 className="text-[#342c49] font-sans text-xl font-extrabold leading-none tracking-tight lg:text-2xl">
                          {blog.title}
                        </h3>
                        <h5 className="text-[#988ab5] mb-4 text-base text-gray-700 md:text-lg">
                          {addEllipsis(blog.description, 250)}
                        </h5>{" "}
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </section>
  );
};

export default Blogs;
