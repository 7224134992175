//jshint esversion:6
import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { Toaster } from "react-hot-toast";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Blogs from "./pages/Blogs";
import PublicRoute from "./components/PublicRoute";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./pages/Dashboard/Dashboard";
import UserList from "./pages/Dashboard/Admin/UserList";
import ConsultationList from "./pages/Dashboard/Admin/ConsultationList";
import AdminBlogs from "./pages/Dashboard/Admin/AdminBlogs";
import Layout from "./pages/Layout/Layout";
import Profile from "./pages/Dashboard/Profile";
import Consultation from "./pages/Dashboard/Consultation";
import Appointments from "./pages/Dashboard/Appointments";
import AdminDashboard from "./pages/Dashboard/Admin/AdminDashboard";
import Notifications from "./pages/Dashboard/Notifications";
import BlogsDetail from "./pages/BlogsDetail";
import AdminPayment from "./pages/Dashboard/Admin/AdminPayment";
import UserPayment from "./pages/Dashboard/UserPayment";
import Contact from "./pages/Contact";
import Messenger from "./pages/Dashboard/Message/messenger";
import UserMessenger from "./pages/Dashboard/Message/userMessenger";
import UserPaymentSuccess from "./pages/Dashboard/UserPaymentSuccess";
import UserPaymentFailed from "./pages/Dashboard/UserPaymentFailed";

const App = () => {
  const { loading } = useSelector((state) => state.alerts);
  const { user } = useSelector((state) => state.user);

  return (
    <section>
      {loading && (
        <div className="spinner-parent">
          <div className="spinner-border" role="status"></div>
        </div>
      )}
      <Toaster
        toastOptions={{ className: "toasters" }}
        position="top-center"
        reverseOrder={false}
      />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:id" element={<BlogsDetail />} />

        {user?.role === "user" ? (
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
        )}

        <Route
          path="/consultations"
          element={
            <ProtectedRoute>
              <Consultation />
            </ProtectedRoute>
          }
        />
        <Route
          path="/appointments"
          element={
            <ProtectedRoute>
              <Appointments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/userpayments"
          element={
            <ProtectedRoute>
              <UserPayment />
            </ProtectedRoute>
          }
        />

        <Route
          path="/userpaymentssuccess"
          element={
            <ProtectedRoute>
              <UserPaymentSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/userpaymentsfailed"
          element={
            <ProtectedRoute>
              <UserPaymentFailed />
            </ProtectedRoute>
          }
        />

        <Route
          path="/layout"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/userlist"
          element={
            <ProtectedRoute>
              <UserList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/consultationlist"
          element={
            <ProtectedRoute>
              <ConsultationList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/adminblogs"
          element={
            <ProtectedRoute>
              <AdminBlogs />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/payments"
          element={
            <ProtectedRoute>
              <AdminPayment />
            </ProtectedRoute>
          }
        />

        <Route
          path="/notifications"
          element={
            <ProtectedRoute>
              <Notifications />
            </ProtectedRoute>
          }
        />
        {user?.role === "admin" ? (
          <Route
            path="/messenger"
            element={
              <ProtectedRoute>
                <Messenger />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route
            path="/messenger"
            element={
              <ProtectedRoute>
                <UserMessenger />
              </ProtectedRoute>
            }
          />
        )}
      </Routes>
    </section>
  );
};

export default App;
