import manaliorange from "../../Images/manali-orange.jpg";

const AboutMe = () => {
  return (
    <section className="bg-[#342c49] font-['poppins'] text-[#fbf8ff]">
      <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div class="md:grid md:grid-cols-2 mt-12 mb-12 md:items-center md:gap-12 xl:gap-32">
          <div className="grid grid-row-2 justify-center">
            <div className="mx-auto">
              <img
                className="mx-auto object-cover w-60 h-60 mb-4 rounded-full shadow"
                src={manaliorange}
                alt="Person"
              />
              <p className="text-3xl font-bold">Dr. Manali Somani</p>
            </div>

            <p className="text-m text-center">
              MDS in Prosthodontics and Crown & Bridge and Implantology
            </p>
          </div>
          <div class="mt-5 sm:mt-8 lg:mt-0">
            <div class="space-y-6 sm:space-y-8">
              <div class="space-y-2 md:space-y-4">
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight sm:text-4xl sm:leading-none">
                  {" "}
                  Know Me!
                </h2>
                <p className="text-base text-[#fbf8ff] md:text-xl">
                  <span>Manali Somani</span> is a specialised dentist with
                  expertise in{" "}
                  <span>
                    Prosthodontics and Crown & Bridge and Implantology
                  </span>
                  . Having completed her <span>MDS</span> from Rajasthan
                  University of Health & Science, <span>India</span> in 2019,
                  she has gained multiple years of consulting and clinical
                  experience. Dr. Manali Somani moved to Baden-Wuettemberg state
                  of <span>Germany</span> to gain more diverse experience.
                  Currently she holds a license to practice dentistry in{" "}
                  <span>Germany</span> and continues broadening her clinical
                  experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AboutMe;
