import { Input, Row, Select, Table } from "antd";
import { Option } from "antd/lib/mentions";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Layout/Layout";
import { hideLoading, showLoading } from "../../../redux/alertsSlice";
import toast from "react-hot-toast";
import axios from "axios";
import { Tabs } from "flowbite-react";

const AdminPaymentV1 = ({ onFormSubmit = () => {} }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [searchedText, setSearchedText] = useState("");
  const [users, setUsers] = useState([]);
  const [consultations, setConsultations] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [sentPaymentData, setSentPaymentData] = useState([]);
  const [receivedPaymentData, setReceivedPaymentData] = useState([]);
  const [emailId, setEmailId] = useState("");
  const [appointment, setAppointments] = useState([]);
  const tabsRef = useRef(null);
  const finishHandler = async (values, record) => {
    try {
      dispatch(showLoading());
      const response = await axios.post(
        "/api/admin/sendpaymentrequest",
        {
          ...values,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      dispatch(hideLoading());
      if (response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      dispatch(hideLoading());
      toast.error("Something Went Wrong");
    }
  };

  const getUserData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/admin/get-all-usernames", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setUsers(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  const getConsultationData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/admin/get-all-consultations", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setConsultations(response.data.data.approvedconsultation);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    getConsultationData();
  }, []);

  const getSentPaymentData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get(
        "/api/admin/get-all-sent-payment-request",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        setSentPaymentData(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getSentPaymentData();
  }, []);

  const getReceivedPaymentData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/admin/get-all-received-payment", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setReceivedPaymentData(response.data.data);
        console.log(setReceivedPaymentData);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getReceivedPaymentData();
  }, []);

  const changePaymentStatus = async (record, status) => {
    try {
      dispatch(showLoading());
      const response = await axios.post(
        "/api/admin/change-payment-status",
        { paymentId: record._id, id: record.id, status: status },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        toast.success(response.data.message);
        getSentPaymentData();
      }
    } catch (error) {
      toast.error("Error Changing Status");

      dispatch(hideLoading());
    }
  };

  const handleEmailChange = (value) => {
    setEmailId(value);
  };

  const handleFormSubmit = () => {
    axios
      .get(`/api/admin/payments/${emailId}`)
      .then((response) => {
        setAppointments(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return String(record.email).toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Cost",
      dataIndex: "cost",
    },
    {
      title: "Currency",
      dataIndex: "currency",
    },
    {
      title: "Status",
      dataIndex: "status",
    },

    {
      title: "Payment Status",
      dataIndex: "actions",
      render: (text, record) => (
        <div>
          <a
            className="actionbtn"
            onClick={() => changePaymentStatus(record, "Received")}
          >
            Credited?{" "}
          </a>
        </div>
      ),
    },
  ];

  const receivedPaymentColumn = [
    {
      title: "Email",
      dataIndex: ["products", "0", "email"],
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return String(record.products[0].email)
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    },
    {
      title: "Customer Id",
      dataIndex: "customerId",
    },
    {
      title: "Payment Id",
      dataIndex: "paymentIntentId",
    },
    {
      title: "Description",
      dataIndex: "products",
      render: (products) =>
        products.map((products) => products.description).join(),
    },
    {
      title: "Cost",
      dataIndex: "total",
      render: (total, record) => (
        <h1>
          {total / 100} {record.products[0].currency}
        </h1>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
    },
  ];

  const sendcolumns = [
    {
      title: "Brief Description",
      dataIndex: "description",
      width: "20%",
    },
    {
      title: "Detailed Description",
      dataIndex: "detaileddescription",
      width: "40%",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      render: (text, record) => {
        return (
          <input
            className="!mb-0 !mt-0 h-12 w-1/2 text-base text-[#342c49] placeholder-gray-300 transition duration-500 ease-in-out transform border rounded-lg bg-[#fbf8ff] border-[#342c49] focus:outline-none focus:border-[#342c49] focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
            value={text}
            onChange={(e) => {
              record.cost = e.target.value;
            }}
          />
        );
      },
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (text, record) => {
        return (
          <input
            className="!mb-0 !mt-0 h-12 w-1/2 text-base text-[#342c49] placeholder-gray-300 transition duration-500 ease-in-out transform border rounded-lg bg-[#fbf8ff] border-[#342c49] focus:outline-none focus:border-[#342c49] focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
            value={text}
            onChange={(e) => {
              record.currency = e.target.value;
            }}
          />
        );
      },
    },
    {
      title: "Send Payment Request",
      dataIndex: "actions",
      render: (text, record) => (
        <div>
          <a className="actionbtn" onClick={() => finishHandler(record)}>
            Send
          </a>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <section className="text-[#342c49] font-['poppins']">
        <h1 className="text-4xl mb-3">Payment</h1>
        <hr></hr>
        <Tabs.Group
          style="default"
          ref={tabsRef}
          onActiveTabChange={(tab) => setActiveTab(tab)}
        >
          <Tabs.Item active title="Send">
            <h5 className="mt-2 mb-5">
              *First Consultation is Free of Charge! Pricing for further
              consultations will be tailored based on the case*
            </h5>
            <Select
              className="w-[13.2rem] sm:w-[20rem] md:w-[20rem] lg:w-[20rem] xl:w-[20rem]"
              placeholder="Name"
              onChange={handleEmailChange}
            >
              {users &&
                users.map((username) => (
                  <Option value={username.name}></Option>
                ))}
            </Select>
            <button
              type="submit"
              onClick={handleFormSubmit}
              class="w-full mt-6 ml-0 sm:mt-0 sm:ml-4 md:mt-0 md:ml-4 lg:mt-0 lg:ml-4 xl:mt-0 xl:ml-4 h-16 sm:w-auto inline-flex justify-center items-center gap-x-3 text-center bg-[#988ab5] text-[#fbf8ff] hover:bg-gradient-to-r from-[#342c49] to-[#342c49] border border-transparent font-medium rounded-md transition py-3 px-4"
            >
              Show Consultations
            </button>

            <section className="h-screen overflow-auto">
              <Table
                className="mt-0 overflow-x-auto overflow-y-auto"
                columns={sendcolumns}
                pagination={{
                  pageSize: 100000,
                }}
                dataSource={appointment}
              />
            </section>
          </Tabs.Item>
          <Tabs.Item active title="Sent">
            <Input.Search
              className="block w-1/2 h-16 mt-4 px-5 py-3 text-base md:h-16 lg:h-16"
              placeholder="Search here with Email..."
              onSearch={(value) => {
                setSearchedText(value);
              }}
            />
            <section className="h-screen overflow-auto">
              <Table
                className="mt-0 overflow-x-auto overflow-y-auto"
                columns={columns}
                pagination={{
                  pageSize: 100000,
                }}
                dataSource={sentPaymentData}
              />
            </section>
          </Tabs.Item>
          <Tabs.Item active title="Received">
            <Input.Search
              className="block w-1/2 h-16 mt-4 px-5 py-3 text-base md:h-16 lg:h-16"
              placeholder="Search here with Email..."
              onSearch={(value) => {
                setSearchedText(value);
              }}
            />
            <section className="h-screen overflow-auto">
              <Table
                className="mt-0 overflow-x-auto overflow-y-auto"
                columns={receivedPaymentColumn}
                pagination={{
                  pageSize: 100000,
                }}
                dataSource={receivedPaymentData}
              />
            </section>
          </Tabs.Item>
        </Tabs.Group>
      </section>
    </Layout>
  );
};

export default AdminPaymentV1;
