import React from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { hideLoading, showLoading } from "../../redux/alertsSlice";
import { useDispatch } from "react-redux";
import { Button, Form, Input } from "antd";
import Dentistry from "../../Images/Clinic_Isometric-nobg.png";

const Center = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    try {
      dispatch(showLoading());
      const response = await axios.post("/api/user/signup", values);
      dispatch(hideLoading());
      if (response.data.success) {
        toast.success(response.data.message);
        navigate("/");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      dispatch(hideLoading());
      toast.error("Something Went Wrong");
    }
  };

  return (
    <section className="bg-[#fbf8ff] font-['poppins']">
      <div class="max-w-[85rem] mx-auto px-4 py-10 sm:px-6 lg:px-8">
        <div class="grid md:grid-cols-2 lg:grid-cols-7 lg:gap-x-8 xl:gap-x-12 lg:items-center">
          <div class="lg:col-span-3">
            <h2 className="text-[#342c49] text-center sm:text-center md:text-left md:mx-auto lg:text-left lg:mx-auto xl:text-left xl:mx-auto uppercase mb-6 font-['poppins'] text-6xl font-bold tracking-wide leading-tight sm:text-[5rem] sm:leading-none md:text-6xl lg:text-7xl">
              I can <br></br>
              <span className="text-[#342c49] tracking-widest">Crown</span>{" "}
              <br></br>
              anyone
              <br />
              <span className="text-[#342c49] tracking-widest"> Bridge </span>
              <br></br>
              <span className="inline-block">any Gaps</span>
            </h2>
            <div class="mt-5 lg:mt-8 flex flex-col items-center gap-2 sm:flex-row sm:gap-3">
              <button className="w-full sm:w-auto inline-flex justify-center items-center gap-x-3 text-center text-l font-medium text-gray-900 rounded-lg group bg-[#988ab5] text-[#fbf8ff] hover:bg-gradient-to-r from-[#342c49] to-[#342c49]">
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-[#988ab5] text-[#fbf8ff] hover:bg-gradient-to-r from-[#342c49] to-[#342c49] rounded-md group-hover:bg-opacity-0">
                  <Link to="/contact">Contact Me</Link>
                </span>
              </button>{" "}
              <button className="w-full sm:w-auto inline-flex justify-center items-center gap-x-3 text-center text-l font-medium text-gray-900 rounded-lg group bg-[#988ab5] text-[#fbf8ff] hover:bg-gradient-to-r from-[#342c49] to-[#342c49]">
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-[#988ab5] text-[#fbf8ff] hover:bg-gradient-to-r from-[#342c49] to-[#342c49] rounded-md group-hover:bg-opacity-0">
                  <Link to="/blogs">Explore Dentistry</Link>
                </span>
              </button>{" "}
            </div>
            <Form onFinish={onFinish}>
              <div class="mt-5 mb-10 lg:mt-8 flex flex-col items-start gap-2 sm:flex-row sm:gap-3">
                <div class="w-full sm:w-auto">
                  <Form.Item name="email">
                    <Input
                      className="block w-full px-5 py-3 text-base text-[#342c49] placeholder-[#988ab5] transition duration-500 ease-in-out transform border border-[#342c49] rounded-lg bg-[#fbf8ff] focus:outline-none focus:border-[#342c49] focus:ring-1 focus:ring-[#342c49] focus:ring-offset-2 focus:ring-offset-[#988ab5]"
                      placeholder="Sign-Up For Newsletter"
                    />
                  </Form.Item>
                </div>
                <div class="w-full sm:w-auto">
                  <button
                    type="submit"
                    class="w-full sm:w-auto inline-flex justify-center items-center gap-x-3 text-center bg-[#988ab5] text-[#fbf8ff] hover:bg-gradient-to-r from-[#342c49] to-[#342c49] border border-transparent font-medium rounded-md transition py-3 px-4"
                  >
                    Sign-Up{" "}
                  </button>
                </div>
              </div>
            </Form>
          </div>

          <div class="hidden sm:hidden md:flex lg:col-span-4 mt-10 lg:mt-0">
            <img class="w-full rounded-xl" alt="dentistry" src={Dentistry} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Center;
