import axios from "axios";
import { useState, useEffect } from "react";
import noAvatar from "../../../Images/default-avatar.png";
import { useSelector } from "react-redux";

const Conversation = ({ conversation, currentUser }) => {
  const { user } = useSelector((state) => state.user);

  const [userInfos, setUserInfos] = useState([]);
  console.log(conversation);
  useEffect(() => {
    async function fetchUserInfos() {
      try {
        const promises = conversation.members.map((memberId) => {
          return axios.get(`/api/admin/get-user-info-conversation/${memberId}`);
        });
        const responses = await Promise.all(promises);
        const data = responses.map((response) => response.data.data);
        setUserInfos(data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchUserInfos();
  }, [conversation.members]);
  if (!conversation) {
    return null;
  }
  console.log(userInfos);
  return (
    <section
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
      className="mt-[1rem]"
    >
      {" "}
      {userInfos.length > 0 && (
        <>
          {userInfos.map((userInfo, index) => {
            const userId = userInfo.userName[0]?._id;
            if (userId !== user._id) {
              return (
                <div className="grid grid-cols-4" key={index}>
                  <div>
                    <img
                      src={
                        userInfo.profilePicture.length > 0
                          ? userInfo.profilePicture[0].image
                          : noAvatar
                      }
                      className="w-12 h-12"
                      alt="Profile"
                      onLoad={() => console.log("Image loaded:", this.src)}
                    />
                  </div>
                  <div
                    className="col-span-3"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h1 className="ml-3">{userInfo.userName[0]?.name}</h1>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </>
      )}{" "}
    </section>
  );
};

export default Conversation;
