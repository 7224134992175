import { Badge } from "antd";
import axios from "axios";
import { Dropdown, Navbar, Sidebar } from "flowbite-react";
import { React, useState, useEffect } from "react";
import {
  HiChartPie,
  HiViewBoards,
  HiInbox,
  HiUser,
  HiBookmark,
  HiLogout,
  HiOutlineBell,
  HiCreditCard,
} from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../../redux/alertsSlice";
import { setUser } from "../../redux/userSlice";
import noAvatar from "../../Images/default-avatar.png";

const Layout = ({ children }) => {
  const { user } = useSelector((state) => state.user);
  const [isDesktop, setDesktop] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profilepicture, setProfilePicture] = useState();

  const getProfilePicture = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/user/get-profile-picture", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setProfilePicture(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getProfilePicture();
  }, []);

  useEffect(() => {
    if (window.innerWidth > 640) {
      setDesktop(true);
    } else {
      setDesktop(false);
    }
    const updateMedia = () => {
      if (window.innerWidth > 640) {
        setDesktop(true);
      } else {
        setDesktop(false);
      }
    };
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <section className="font-['poppins'] text-[#342c49] ">
      <div className="bg-[#fbf8ff] h-fit sm:h-fit md:h-fit xl:h-fit">
        <div>
          <Navbar
            className="font-['poppins'] dashnav bg-[#fbf8ff]"
            fluid={true}
          >
            <Navbar.Brand>
              <span className="self-center ml-4 text-2xl font-semibold sm:text-3xl md:text-3xl lg:text-3xl xl:text-3xl">
                <Link to="/">Dr. Manali Somani</Link>
              </span>
            </Navbar.Brand>

            <div className="flex mr-4 md:order-2">
              <Badge
                count={user?.unseenNotifications.length}
                onClick={() => navigate("/notifications")}
                className="mt-3 mr-3 lg:mr-4 xl:mr-4 sm:mr-4"
              >
                <a>
                  <HiOutlineBell className="w-7 h-7 mt-0 sm:mt-1 md:mt-1 lg:mt-1 xl:mt-1 mr-0 lg:mr-1 xl:mr-1 sm:mr-1 text-[#342c49] sm:w-8 sm:h-8 md:w-8 md:h-8 lg:w-8 lg:h-8 xl:w-8 xl:h-8" />
                </a>
              </Badge>

              <Dropdown
                arrowIcon={false}
                inline={true}
                label={
                  profilepicture && profilepicture.length > 0 ? (
                    profilepicture.map((picture, index) => (
                      <img
                        className="w-[3rem] h-[3rem] rounded-full sm:w-[4rem] sm:h-[4rem] md:w-[4rem] md:h-[4rem] lg:w-[4rem] lg:h-[4rem] xl:w-[4rem] xl:h-[4rem]"
                        src={picture.image}
                        alt="profilepicture"
                      />
                    ))
                  ) : (
                    <img
                      className="w-[3rem] h-[3rem] rounded-full sm:w-[4rem] sm:h-[4rem] md:w-[4rem] md:h-[4rem] lg:w-[4rem] lg:h-[4rem] xl:w-[4rem] xl:h-[4rem]"
                      src={noAvatar}
                      alt="profilepicture"
                    />
                  )
                }
              >
                <Dropdown.Header>
                  <span className="block text-sm">{user?.name}</span>
                  <span className="block truncate text-sm font-medium">
                    {user?.email}
                  </span>
                </Dropdown.Header>
                <Dropdown.Item
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                >
                  Dashboard
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    navigate("/messenger");
                  }}
                >
                  Messages
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  Settings
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={() => {
                    localStorage.clear();
                    dispatch(setUser(undefined, null));

                    navigate("/login");
                  }}
                >
                  Sign out
                </Dropdown.Item>
              </Dropdown>
            </div>
          </Navbar>{" "}
        </div>
        <hr></hr>

        <div className="absolute bg-primary top-22 left-0 h-screen">
          {user?.role === "user" ? (
            <div>
              {isDesktop ? (
                <div className=" !bg-primary sidebar relative w-64">
                  <Sidebar className="!bg-primary" collapseBehavior="collapse">
                    <Sidebar.Items>
                      <Sidebar.ItemGroup>
                        <Sidebar.Item
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiChartPie}
                        >
                          <Link to="/dashboard">Dashboard</Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiViewBoards}
                        >
                          <Link to="/consultations">Consultations</Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiInbox}
                        >
                          <Link to="/appointments">Appointments</Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiCreditCard}
                        >
                          <Link to="/userpayments">Payments</Link>
                        </Sidebar.Item>

                        <Sidebar.Item
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiUser}
                        >
                          <Link to="/profile">Profile</Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiLogout}
                          onClick={() => {
                            localStorage.clear();
                            dispatch(setUser(undefined, null));

                            navigate("/login");
                          }}
                        >
                          <Link to="/login">Log Out</Link>
                        </Sidebar.Item>
                      </Sidebar.ItemGroup>
                    </Sidebar.Items>
                  </Sidebar>
                </div>
              ) : (
                <div className="w-16">
                  <Sidebar
                    className="!bg-primary"
                    collapseBehavior="collapse"
                    collapsed
                  >
                    <Sidebar.Items>
                      <Sidebar.ItemGroup>
                        <Sidebar.Item
                          href="/dashboard"
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiChartPie}
                        >
                          <Link to="/dashboard">Dashboard</Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          href="/consultations"
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiViewBoards}
                        >
                          <Link to="/consultations">Consultations</Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          href="/appointments"
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiInbox}
                        >
                          <Link to="/appointments">Appointments</Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          href="/userpayments"
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiCreditCard}
                        >
                          <Link to="/userpayments">Payments</Link>
                        </Sidebar.Item>

                        <Sidebar.Item
                          href="/profile"
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiUser}
                        >
                          <Link to="/profile">Profile</Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          href="/login"
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiLogout}
                          onClick={() => {
                            localStorage.clear();
                            dispatch(setUser(undefined, null));

                            navigate("/login");
                          }}
                        >
                          <Link to="/login">Log Out</Link>
                        </Sidebar.Item>
                      </Sidebar.ItemGroup>
                    </Sidebar.Items>
                  </Sidebar>
                </div>
              )}
            </div>
          ) : (
            <div>
              {isDesktop ? (
                <div className="w-64">
                  <Sidebar collapseBehavior="collapse">
                    <Sidebar.Items>
                      <Sidebar.ItemGroup>
                        <Sidebar.Item
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiChartPie}
                        >
                          <Link to="/dashboard">Dashboard</Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiViewBoards}
                        >
                          <Link to="/admin/userlist">Users</Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiInbox}
                        >
                          <Link to="/admin/consultationlist">
                            Consultations
                          </Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiBookmark}
                        >
                          <Link to="/admin/adminblogs">Blogs</Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiCreditCard}
                        >
                          <Link to="/admin/payments">Payments</Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiUser}
                        >
                          <Link to="/profile">Profile</Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiLogout}
                          onClick={() => {
                            localStorage.clear();
                            dispatch(setUser(undefined, null));

                            navigate("/login");
                          }}
                        >
                          <Link to="/login">Log Out</Link>
                        </Sidebar.Item>
                      </Sidebar.ItemGroup>
                    </Sidebar.Items>
                  </Sidebar>
                </div>
              ) : (
                <div className="w-16">
                  <Sidebar collapseBehavior="collapse" collapsed>
                    <Sidebar.Items>
                      <Sidebar.ItemGroup>
                        <Sidebar.Item
                          href="/dashboard"
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiChartPie}
                        >
                          <Link to="/dashboard">Dashboard</Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          href="/admin/userlist"
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiViewBoards}
                        >
                          <Link to="/admin/userlist">Users</Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          href="/admin/consultationlist"
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiInbox}
                        >
                          <Link to="/admin/consultationlist">
                            Consultations
                          </Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          href="/admin/adminblogs"
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiBookmark}
                        >
                          <Link to="/admin/adminblogs">Blogs</Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          href="/admin/payments"
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiCreditCard}
                        >
                          <Link to="/admin/payments">Payments</Link>
                        </Sidebar.Item>

                        <Sidebar.Item
                          href="/profile"
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiUser}
                        >
                          <Link to="/profile">Profile</Link>
                        </Sidebar.Item>
                        <Sidebar.Item
                          href="/login"
                          className="!text-[#342c49] hover:!bg-[#988ab5] !text-[1.2rem] !font-medium mb-12 mt-12"
                          icon={HiLogout}
                          onClick={() => {
                            localStorage.clear();
                            dispatch(setUser(undefined, null));

                            navigate("/login");
                          }}
                        >
                          <Link to="/login">Log Out</Link>
                        </Sidebar.Item>
                      </Sidebar.ItemGroup>
                    </Sidebar.Items>
                  </Sidebar>
                </div>
              )}{" "}
            </div>
          )}
        </div>

        <div class={`p-4 mt-10 ${isDesktop ? "ml-64" : "ml-16"}`}>
          <div
            className={
              "p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700"
            }
          >
            <div>{children}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Layout;
