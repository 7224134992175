import { Form, Input, Select } from "antd";
import { Option } from "antd/lib/mentions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout/Layout";
import { hideLoading, showLoading } from "../../redux/alertsSlice";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import picturelist from "./profilepicturelist";
import { Card } from "flowbite-react";

const Profile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [displayPicture, setDisplayPicture] = useState("");
  const [name, setName] = useState("");

  const navigate = useNavigate();

  const uploadpictureHandler = async (values) => {
    try {
      dispatch(showLoading());
      const response = await axios.post(
        "/api/user/upload-picture",
        {
          ...values,
          userId: user._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        toast.success(response.data.message);
        navigate("/dashboard");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      dispatch(hideLoading());
      toast.error("Something Went Wrong");
    }
  };
  const getProfilePicture = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/api/user/get-profile-picture", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setDisplayPicture(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
    setLoading(false); // set loading to false when finished fetching data
  };

  useEffect(() => {
    const saveddp = window.localStorage.setItem(
      "displaypicture",
      JSON.stringify(displayPicture)
    );
    getProfilePicture();
    const pictureavailable = !!setDisplayPicture.length;
  }, []);

  const updatePictureHandler = async (values) => {
    try {
      dispatch(showLoading());
      const response = await axios.post(
        "/api/user/update-profile",
        {
          ...values,
          userId: user._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        toast.success(response.data.message);
        getProfilePicture();
        navigate("/profile");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      dispatch(hideLoading());
      toast.error("Something Went Wrong");
    }
  };

  const nameFinishHandler = async (values) => {
    try {
      dispatch(showLoading());
      const response = await axios.post(
        "/api/user/update-name",
        {
          ...values,
          userId: user._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        toast.success(response.data.message);
        setName(response.data.name);
        navigate("/profile");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      dispatch(hideLoading());
      toast.error("Something Went Wrong");
    }
  };

  const nameChangeHandler = (e) => {
    setName(e.target.value);
  };

  console.log(displayPicture);

  if (loading) {
    // show a loading indicator while fetching data
    return <div>Loading...</div>;
  }
  return (
    <Layout>
      <section className="text-[#342c49] font-['poppins'] h-fit">
        <h1 className="text-4xl mb-3">Profile</h1>
        <hr></hr>
        <div className="px-0 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-10 lg:px-8 lg:py-18">
          <div className="grid gap-5">
            <div className="flex flex-col justify-center">
              {displayPicture &&
                displayPicture.map((picture, index) => (
                  <Card className="!border-none !shadow-none !drop-shadow-none">
                    <img
                      className="mb-3 h-80 w-80 rounded-full shadow-lg"
                      src={picture.image}
                      alt={user.name}
                    />
                  </Card>
                ))}

              {displayPicture.length === 0 && (
                <Form onFinish={uploadpictureHandler}>
                  <Form.Item label="Select Your Profile Picture" name="image">
                    <Select
                      id="image"
                      className="profilepicture !w-[8rem] border border-[#342c49] rounded-lg bg-[#fbf8ff] focus:outline-none focus:border-[#342c49] focus:ring-1 focus:ring-[#342c49] focus:ring-offset-2 focus:ring-offset-[#988ab5]"
                    >
                      <Option value="-"></Option>
                      <Option value={picturelist[0].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[0].label}
                          src={picturelist[0].src}
                        />
                      </Option>
                      <Option value={picturelist[1].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[1].label}
                          src={picturelist[1].src}
                        />
                      </Option>
                      <Option value={picturelist[2].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[2].label}
                          src={picturelist[2].src}
                        />
                      </Option>
                      <Option value={picturelist[3].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[3].label}
                          src={picturelist[3].src}
                        />
                      </Option>
                      <Option value={picturelist[4].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[4].label}
                          src={picturelist[4].src}
                        />
                      </Option>
                      <Option value={picturelist[5].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[5].label}
                          src={picturelist[5].src}
                        />
                      </Option>
                      <Option value={picturelist[6].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[6].label}
                          src={picturelist[6].src}
                        />
                      </Option>
                      <Option value={picturelist[7].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[7].label}
                          src={picturelist[7].src}
                        />
                      </Option>
                      <Option value={picturelist[8].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[8].label}
                          src={picturelist[8].src}
                        />
                      </Option>
                      <Option value={picturelist[9].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[9].label}
                          src={picturelist[9].src}
                        />
                      </Option>
                      <Option value={picturelist[10].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[10].label}
                          src={picturelist[10].src}
                        />
                      </Option>
                      <Option value={picturelist[11].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[11].label}
                          src={picturelist[11].src}
                        />
                      </Option>
                      <Option value={picturelist[12].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[12].label}
                          src={picturelist[12].src}
                        />
                      </Option>
                      <Option value={picturelist[13].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[13].label}
                          src={picturelist[13].src}
                        />
                      </Option>
                      <Option value={picturelist[14].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[14].label}
                          src={picturelist[14].src}
                        />
                      </Option>
                      <Option value={picturelist[15].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[15].label}
                          src={picturelist[15].src}
                        />
                      </Option>
                      <Option value={picturelist[16].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[16].label}
                          src={picturelist[16].src}
                        />
                      </Option>
                      <Option value={picturelist[17].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[17].label}
                          src={picturelist[17].src}
                        />
                      </Option>
                      <Option value={picturelist[18].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[18].label}
                          src={picturelist[18].src}
                        />
                      </Option>
                    </Select>
                  </Form.Item>
                  <button
                    className="relative inline-flex items-center h-10 w-[12rem] justify-center p-0.5 mb-2 mr-2 overflow-hidden text-l font-medium text-gray-900 rounded-lg group bg-[#988ab5] text-[#fbf8ff] hover:bg-gradient-to-r from-[#342c49] to-[#342c49]"
                    type="submit"
                  >
                    Set Profile Picture
                  </button>
                </Form>
              )}
              {displayPicture.length > 0 && (
                <Form onFinish={updatePictureHandler}>
                  <Form.Item label="Select Your Profile Picture" name="image">
                    <Select
                      id="image"
                      className="profilepicture !w-[8rem] border border-[#342c49] rounded-lg bg-[#fbf8ff] focus:outline-none focus:border-[#342c49] focus:ring-1 focus:ring-[#342c49] focus:ring-offset-2 focus:ring-offset-[#988ab5]"
                    >
                      <Option value="-"></Option>
                      <Option value={picturelist[0].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[0].label}
                          src={picturelist[0].src}
                        />
                      </Option>
                      <Option value={picturelist[1].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[1].label}
                          src={picturelist[1].src}
                        />
                      </Option>
                      <Option value={picturelist[2].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[2].label}
                          src={picturelist[2].src}
                        />
                      </Option>
                      <Option value={picturelist[3].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[3].label}
                          src={picturelist[3].src}
                        />
                      </Option>
                      <Option value={picturelist[4].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[4].label}
                          src={picturelist[4].src}
                        />
                      </Option>
                      <Option value={picturelist[5].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[5].label}
                          src={picturelist[5].src}
                        />
                      </Option>
                      <Option value={picturelist[6].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[6].label}
                          src={picturelist[6].src}
                        />
                      </Option>
                      <Option value={picturelist[7].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[7].label}
                          src={picturelist[7].src}
                        />
                      </Option>
                      <Option value={picturelist[8].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[8].label}
                          src={picturelist[8].src}
                        />
                      </Option>
                      <Option value={picturelist[9].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[9].label}
                          src={picturelist[9].src}
                        />
                      </Option>
                      <Option value={picturelist[10].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[10].label}
                          src={picturelist[10].src}
                        />
                      </Option>
                      <Option value={picturelist[11].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[11].label}
                          src={picturelist[11].src}
                        />
                      </Option>
                      <Option value={picturelist[12].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[12].label}
                          src={picturelist[12].src}
                        />
                      </Option>
                      <Option value={picturelist[13].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[13].label}
                          src={picturelist[13].src}
                        />
                      </Option>
                      <Option value={picturelist[14].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[14].label}
                          src={picturelist[14].src}
                        />
                      </Option>
                      <Option value={picturelist[15].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[15].label}
                          src={picturelist[15].src}
                        />
                      </Option>
                      <Option value={picturelist[16].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[16].label}
                          src={picturelist[16].src}
                        />
                      </Option>
                      <Option value={picturelist[17].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[17].label}
                          src={picturelist[17].src}
                        />
                      </Option>
                      <Option value={picturelist[18].src}>
                        {" "}
                        <img
                          width="50"
                          height="50"
                          alt={picturelist[18].label}
                          src={picturelist[18].src}
                        />
                      </Option>
                    </Select>
                  </Form.Item>
                  <button
                    className="relative inline-flex items-center h-10 w-[12rem] justify-center p-0.5 mb-2 mr-2 overflow-hidden text-l font-medium text-gray-900 rounded-lg group bg-[#988ab5] text-[#fbf8ff] hover:bg-gradient-to-r from-[#342c49] to-[#342c49]"
                    type="submit"
                  >
                    Update Profile Picture
                  </button>
                </Form>
              )}
            </div>
            <Form className="flex" onFinish={nameFinishHandler}>
              <div className="flex flex-col">
                <Form.Item name="name">
                  <Input
                    className="inline w-[10rem] h-10 mb-0 px-5 py-3 text-base text-[#342c49] placeholder-[#988ab5] transition duration-500 ease-in-out transform border border-[#342c49] rounded-lg bg-[#fbf8ff] focus:outline-none focus:border-[#342c49] focus:ring-1 focus:ring-[#342c49] focus:ring-offset-2 focus:ring-offset-[#988ab5]"
                    defaultValue={user.name}
                    onChange={nameChangeHandler}
                  />
                </Form.Item>
                <div>
                  <button
                    className="inline items-center h-10 w-[8rem] justify-center p-0.5 mb-0 mr-2 overflow-hidden text-l font-medium text-gray-900 rounded-lg group bg-[#988ab5] text-[#fbf8ff] hover:bg-gradient-to-r from-[#342c49] to-[#342c49]"
                    type="submit"
                  >
                    Update Name{" "}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Profile;
